<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<dialog :id="id" :class="['c-modal', 'size-message-' + getSize, cssClass]" ref="myDialog">
		<div>
			<header class="text-left">
				<h2 class="modal-header-text">
					<slot name="header"></slot>
				</h2>
			</header>

			<main v-if="type == 'modal'" class="text-left">
				<div v-if="msg">
					{{ msg }}
				</div>
				<div v-if="!msg">
					<slot></slot>
				</div>
			</main>

			<main v-if="type == 'alert'" class="text-left">
				<div v-if="msg">
					{{ msg }}
				</div>
				<div v-if="!msg">
					<slot></slot>
				</div>
			</main>

			<main v-if="type == 'confirm'" class="text-left">
				<div v-if="msg">
					{{ msg }}
				</div>
				<div v-if="!msg">
					<slot></slot>
				</div>
			</main>


			<footer class="text-right">
				<c-button-standard id="modal-close-button" class="modal-close-button" @click="closeModal()" v-if="type == 'modal'">
					<template v-slot:text>Close</template>
				</c-button-standard>


				<c-button-standard id="modal-close-button" class="modal-close-button" @click="closeModal()" v-if="type == 'alert'">
					<template v-slot:text>OK</template>
				</c-button-standard>



				<c-button-standard id="modal-confirm-button" class="modal-close-button" @click="replyConfirm()" v-if="type == 'confirm'">
					<template v-slot:text>Yes</template>
				</c-button-standard>
				<c-button-secondary id="modal-close-button" class="modal-close-button ml-1" @click="replyDecline()" v-if="type == 'confirm'">
					<template v-slot:text>No</template>
				</c-button-secondary>
			</footer>
		</div>
	</dialog>
</template>










<script>
//Initial prototype: https://stackblitz.com/edit/vue3-app-rnk7bj?file=src%2Fcomponents%2Fc-modal2.vue,src%2FApp.vue

export default {

	//emits: ["close"],

	props: {

		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		msg: {
			type: String,
			default: ""
		},

		// Trigger this modal to be opened from a parent calling.
		trigger: {
			type: String,
			default: ""
		},

		// The type of modal this is. Types include:
		// - "modal": a normal modal (pass in any content to display or interact with).
		// - "alert": a read-only message to the user with just the option to close.
		// - "confirm": a confirmation message requiring either a "yes" or "no" style response.
		type: {
			type: String,
			default: "modal"
		},

		// A pre-defined size that is dependancy also on teh provided `type`. Sizes include:
		// - "small" 
		// - "medium"
		// - "large"
		// Later we can have:
		// - "": Not use. Defaults to "auto" unless a custom CSS is passed in.
		// - "auto": Fluid and handled by HTML rendering
		size: {
			type: String,
			default: ""
		},






		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: [String, Array],
			default: ""
		},
	},





	data()
	{
		return {
            dialogVisible: false,
		};
	},





	watch: { 
		trigger: function(newVal, oldVal) {
		
		// Triggered cleared for next usage.
		//if (newVal == "") return;

		console.log("watch::trigger(): newVal=", newVal);

		if (newVal == "Open") 
		{
			this.showModal();
			return;
		}

		if (newVal == "Close")
		{
			// Nothing to do. Just resetting the trigger state...
			// No! When we pass in a close even triggered by something in the custom
			// content (e.g. a custom button), we need to call the trigger handler.
			this.handleCloseTrigger();
			return;
		}

		//this.showModal();
		}
	},


	emits: ["close","confirm","decline"],






	mounted()
	{
		// this.dialogVisible = this.state;

		this.init();
	},





	computed: {
		testX()
		{
			return "testX";
		},

		getSize()
		{
			console.log("modal2.getSize(): this.size=", this.size);
			if (this.size == "") return "small";
			if (this.size == "small") return "small";
			if (this.size == "medium") return "medium";
			if (this.size == "large") return "large";
			if (this.size == "80pc") return "80pc";
			
			console.error(`modal2: Unhandled value '${this.size}' for 'size' prop.`);
			return "small2";
		},
	},





	methods: {
		init()
		{
			// Detect when 'Esc' key is used (that's fine),
			// and ensure a 'close' event is emitted.
			const _this = this;
			this.$refs.myDialog.addEventListener('cancel', (event) => {
			if (_this.type == 'confirm') _this.$emit('close', { response: 'close', isEsc: true });
			
			_this.$emit('close', { response: 'decline', isEsc: true });
			});
		},







		
		showModal() {
			//const dialog = document.querySelector('#myDialog');
			//dialog.showModal();

			//this.myDialog.value.showModal();  // Vue 3

			this.$refs.myDialog.showModal();  // Vue 2
		},



		// Perform the actual close of the modal from a trigger.
		// Don't emit event - that's already happened (to cause the return trigger).
		handleCloseTrigger() {
			this.$refs.myDialog.close();  // Vue 2
		},


		//-- For a standard modal and a "message alert"

		closeModal() {
			//const dialog = document.querySelector('#myDialog');
			//dialog.close();

			this.$refs.myDialog.close();  // Vue 2

			//this.$emit('close');
			this.$emit('close', { response: 'close' });
		},



		//-- For a "confirm alert"

		// Answer "yes".
		replyConfirm()
		{
			this.$refs.myDialog.close();  // Vue 2

			//this.$emit('confirm');
			this.$emit('close', { response: 'confirm' });
		},


		// Answer "no".
		replyDecline()
		{
			this.$refs.myDialog.close();  // Vue 2

			//this.$emit('decline');
			this.$emit('close', { response: 'decline' });
		}
	}
}
</script>






<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	// Emulate Bootstrap .text-right
	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-centre {
		text-align: centre;
	}


	/*html {
		font-family: 'montserrat', sans-serif;
		}*/

	.c-modal {
		padding: 0;
		border: 1px solid #ddd;
		border-radius: 2px;

		// Change top positioning
		position: absolute;
		top: 25%;
		transform: translateY(-100%);
	}

	dialog.c-modal::backdrop {
		background-color: rgba(0, 0, 0, 0.7);
	}





	header {
		padding: 6px;

		margin-bottom: 10px;

		text-align: left;

		/*border-bottom: 1px solid black;*/
		background-color: #c1daeb;
	}

	.modal-header-text {
		margin: 0;

		font-size: 1em;
		font-weight: bold;
	}






	main {
		padding: 6px;
	}






	footer {
		padding: 6px;

		margin-top: 10px
			/*border-top: 1px solid black;*/
	}



	.modal-message {
		font-family: 'quicksand', sans-serif;
		color: #868a8e;
	}

	.modal-close-button {}








	.size-auto {}


	.size-small {
		width: 600px;
	}

	.size-medium {
		width: 800px;
	}

	.size-large {
		width: 1200px;
	}
	
	.size-message-80pc {
		width: 80%;
	}



	/** Size defaults for "modal" type dialogs. */
	.size-modal-small {
		width: 600px;
	}

	.size-modal-medium {
		width: 800px;
	}

	.size-modal-large {
		width: 1200px;
	}


	.size-modal-80pc {
		width: 80%;
	}


	/** Size defaults for "alert" and "confirm" message type dialogs. */
	.size-message-small {
		width: 400px;
	}

	.size-message-medium {
		width: 600px;
	}

	.size-message-large {
		width: 800px;
	}

	.size-message-80pc {
		width: 80%;
	}
</style>
