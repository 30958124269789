<template>
	<div :class="['nav-item', getIsModuleReturnClass]">
		<div class="nav-item-padding">
			<!-- TOP LEVEL: ICON -->

			<!-- Has a URL -->
			<a v-if="getItem.url != ''" :href="getItem.url">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
			</a>

			<!-- No URL, but has a function, just a label -->
			<span v-if="getItem.url == '' && item.func" @click="item.func">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
			</span>

			<!-- No URL or function, just a label -->
			<span v-if="getItem.url == '' && !item.func">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
			</span>



			<!-- TOP LEVEL: Text -->
			<div class="nav-item-text">
				<!-- Has a URL -->
				<a v-if="getItem.url != ''" v-bind:href="getItem.url">
					{{getItem.text}}
				</a>

				<!-- No URL, but has a function, just a label -->
				<span v-if="getItem.url == '' && item.func" class="label-child" @click="item.func">
					<span v-if="getItem.html" v-html="getItem.html"></span>
					{{getItem.text}}
				</span>

				<!-- No URL or function, just a label -->
				<span v-if="getItem.url == '' && !item.func" class="label-child">
					<span v-if="getItem.html" v-html="getItem.html"></span>
					{{getItem.text}}
				</span>
			</div>




			<!-- CHILDREN -->
			<ul v-if="getItem.children && getItem.children.length > 0" style="min-width: 150px;">
				<li v-for="child in getItem.children" :key="getItem.id + child.id " :class="{'is-label': child.url == '' }">
					
					<!-- Has a URL -->
					<a :href="child.url" v-if="child.url != ''">
						<span v-if="child.html" v-html="child.html"></span>
						{{child.text}}
					</a>


					<!-- No URL, but has a function, just a label -->
					<span v-if="child.url == '' && child.func" class="label-child" @click="child.func">
						<span v-if="child.html" v-html="child.html"></span>
						{{child.text}}
					</span>


					<!-- No URL or function, just a label -->
					<span v-if="child.url == '' && !child.func" class="label-child">
						<span v-if="child.html" v-html="child.html"></span>
						{{child.text}}
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>







<script>
export default {
	props: [
		'item'
	],

	data()
	{
		return {

		};
	},



	computed: {
		/**
		 * Return the passed in item. Return via a computed so "undefined" during render is handled gracefully.
		 */
		getItem()
		{
			if (this.item == undefined) return {
				children: []
			};

			return this.item;
		},


		getModule()
		{
			if (this.item == undefined) return '';

			if (this.item.module) return this.item.module;

			return '';
		},

		getIsModuleReturnClass()
		{
			if (this.getModule == "--module-return--") return 'module-return-item';

			return '';
		}
	},


	methods: {
		iconClick()
		{
			this.$root.redirectToPath(this.getItem.url);
		}
	}
}
</script>





<style lang="scss" scoped>
	.fa-icon
	{
		height: 22px;
		width: 22px;
		display: inline-block;


		.svg-inline--fa
		{
			height: 1.6em;		//20 Oct 2020 [JS]: Just a rushed eyeball approximation.	//TODO: Review and fix
		}

		color:#576874;
	}


	.fa-icon:hover
	{
		color:#FDB515;
	}




	


	//-- Overrides for the special "module return" nav item.
	.module-return-item
	{
		margin-right: 1.5rem;
	}

	.module-return-item .nav-item-text
	{
		font-weight: bold;
		// color: navy;
		font-style: italic;
		// text-decoration: underline;

		color:#FDB515;
	}
	.module-return-item .nav-item-text:hover
	{
		color:#576874;
	}

	.module-return-item .fa-icon
	{
		// height: 22px;
		// width: 22px;
		// display: inline-block;


		// .svg-inline--fa
		// {
		// 	height: 1.6em;		//20 Oct 2020 [JS]: Just a rushed eyeball approximation.	//TODO: Review and fix
		// }

		color:#FDB515;

	}
	.module-return-item .fa-icon:hover
	{
		color:#576874;
	}
</style>