<template>
	<el-button 
		:id="id" 
		:name="getName"
		:value="initialValue" 
		:class="['c-button-confirm', getClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
		v-if="origin=='element'"
	>
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
	</el-button>

	<button
		:id="id" 
		:name="getName"
		:value="initialValue" 
		:class="['c-button-confirm','c-native-button', getClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
		@click="onClickNative"
		v-if="origin=='native'">
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
	</button>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	props: {
		// Where is the underlying button coming from. Just needed while we transition from element to native and have to test.
		origin: {
			type: String,
			default: "native"	// "element" (<el-button>) or "native" (<button>)
		},
	},


	mounted()
	{
		//console.log("button-confirm(): type=", this.type);
		this.displayType = this.type;
	},

}
</script>





<style scoped lang="scss">
@import "./shared-styles.scss";
</style>


<style scoped lang="scss">
	.icon {
		margin-right: 10px		
	}


	.c-native-button
	{
		background-color: rgb(64, 158, 255);
		border: 1px solid rgb(64, 158, 255);
		border-radius: 4px;

		color: white;
		font-family: CircularStd, Montserrat, sans-serif;
		font-size: 14px;
		font-weight: 500;
		
		padding: 4px;
		padding-left: 16px;
		padding-right: 16px;
	}


	// Copied from <el-button> type="primary"
	.c-native-button:hover
	{
		// background-color: rgb(64, 158, 255, .7);

		color: #ffffff;
		border-color: #79bbff;
		background-color: #79bbff;
		outline: 0;
	}

	// Copied from <el-button> type="primary"
	.c-native-button:active {
		color: #ffffff;
		border-color: #337ecc;
		background-color: #337ecc;
		outline: 0;
		
	}
</style>