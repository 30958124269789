<template>
	<!-- No goruping -->
	<div v-if="type == 'native' && isSearchable">
		<input 
			type="textd"
			:placeholder="searchablePlaceholderText"
			:id="id" :name="getName" 
			:list="id + '-options'" 
			:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			v-model="value" 
			:disabled="disabled"
			@change="onChange"
			@input="onInput"
			@blur="onBlur"
		/>
		<datalist :id="id + '-options'" :name="getName + '-options'">
			<option v-for="option in getOptions" :value="option.value" :key="id + '-' + option.value">{{option.text}}</option>
		</datalist>
	</div>

	<!-- No goruping -->
	<select 
		:id="id" :name="getName" 
		:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		filterable
		v-model="value" 
		:disabled="disabled"
		@change="onChange"
		@input="onInput"
		@blur="onBlur"
		:multiple="multiple ? true : false"
		v-if="type == 'native' && !isGrouped && !isSearchable">
		<option v-for="option in getOptions" :value="option.value" :key="id + '-' + option.value">{{option.text}}</option>
	</select>


	<!-- Grouped -->
	<select 
		:id="id" :name="getName" 
		:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', 'c-input-native-grouped', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		filterable
		v-model="value" 
		:disabled="disabled"
		@change="onChange"
		@input="onInput"
		@blur="onBlur"
		:multiple="multiple ? true : false"
		v-if="type == 'native' && isGrouped && !isSearchable">
		<template v-for="item in groupedOptions" :key="'one' + item.value">
			<option :value="item.value" v-if="!item.children">{{ item.text }}</option>

			<template v-if="item.children">
				<optgroup :label="item.text">
					<option v-for="option in item.children" :key="item.value + option.value" :value="option.value">{{ option.text }}</option>
				</optgroup>
			</template>
		</template>
	</select>

	<el-select 
		v-model="value" 
		:id="id" :name="getName" 
		:class="['c-input-select', 'simulate-form-control', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		@change="onChangeEl"
		filterable 
		:placeholder="placeholder"
		:allow-create="allowCreate ? true : false"
		:remote="remote ? true : false"
		:remote-method="remoteMethod"
		:loading="loading ? true : false"
		:loading-text="loadingText"
		:default-first-option="defaultFirstOption"
		:multiple="multiple ? true : false"
		:disabled="disabled"
		v-if="type == 'element'">
		<el-option
			v-for="option in getOptions"
			:key="id + '-' + option.value"
			:label="option.text"
			:value="option.value">
			<slot></slot>
		</el-option>
	</el-select>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],
	
	mixins: [allFormElementPropsMixin],

	props: {


		allowCreate: {
			type: Boolean,
		},


		remote: {
			type: Boolean,
		},

		remoteMethod: {
			type: Function,
		},

		defaultFirstOption: {
			type: Boolean
		},

		loading: {
			type: Boolean,
		},

		loadingText: {
			type: String,
		},

		
		initialValue: {
			type: [ Number, String, Array ]
		},
		
		/**
		 * A standard setup of options with "value" and "text" properties.
		 * Use this for a standard list WIHTOUT option groups.
		 */
		options: {
			type: Array,
			default: null
		},


		/**
		 * Does the list have option groups with options inside.
		 */
		isGrouped: {
			type: Boolean,
			required: false,
			default: false
		},



		/**
		 * Can the list be searched?
		 * If `true`, this will override `isGrouped` (though we should look at integrating the 2 in the future).
		 */
		isSearchable: {
			type: Boolean,
			required: false,
			default: false
		},


		searchablePlaceholderText: {
			type: String,
			required: false,
			default: "Start typing, press down, or click to show options to select"
		},




		/**
		 * A standard setup of options with "value" and "text" properties.
		 * Use this for a list WITH option groups.
		 * 
		 * Item structure:
		 * {
		 *   value: "",
		 *   text: "",
		 *   children: [
		 *     {
		 *        value: "",
		 *        text: "",
		 *     }
		 *   ]
		 * }
		 * If `children` does NOT exist on an object, it's just a top level <option>.
		 * If `children` DOES exist then it is an <optgroup> and the children are the <options>s
		 */
		groupedOptions: {
			type: Array,
			default: () => []
		},


		placeholder: {
			type: String,
			required: false,
			default: "Select"
		},


		/**
		 * A custom data set, where "value" and "textd" for standard "options"
		 * are mapped from the "dataValue" and "dataText" (name of the properties in the data items) respectively.
		 */
		data: {
			type: Array,
			default: null
		},


		dataValue: {
			type: String,
			default: ""
		},

		dataText: {
			type: String,
			default: ""
		},


		type: {
			type: String,
			default: "native"
		},


		multiple: {
			type: Boolean,
			default: false
		},



		//TODO: New with Vue 3
        // modelValue: {
        //     type: Array,
        //     default: () => { return []; },
        //     required: true
        // },

        // modelValue: {
        //     type: String,
        //     default: '',
        //     required: true
        // },

        modelValue: {
            type: [String, Array],
            default: '',
            required: true
        },




		
		
	},






	data()
	{
		return {
			value: []
		};
	},






	mounted()
	{
		//TODO: Doing this here is crapping out the value for some reason

		//this.value = this.initialValue;

		//console.log("input-select.mounted()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);
	},




	created()
	{
		this.value = this.initialValue;

		// console.log("input-select.created()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);
	},



	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;

			// console.log("watch[initialValue][" + this.id + "]: value=", this.value, "to=", to, "from=", from);
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},


		getOptions()
		{
			if (this.data)
			{
				// Generate an options array from the supplied raw data and identified value and text fields.
				var options = this.$root.getSelectOptionsFromData(this.data, this.dataValue, this.dataText);

				// console.log("input-select.getOptions(from data): this.dataValue=", this.dataValue);
				// console.log("input-select.getOptions(from data): this.dataText=", this.dataText);
				// console.log("input-select.getOptions(from data): option=", options);

				return options;
			}
			else
			{
				// Use a correctly formatted options list
				return this.options;
			}
		}
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		},


		onInput($event)
		{
			this.$emit('input', $event.target.value);
		},


		// onChange($event)
		// {
		// 	this.$emit('change', $event.target.value);
		// },


		onChange(value)
		{
			console.log("input-select.onChange(): ", this.value);

			//this.$emit('input', this.value);	//TODO: Disabled 20 Jan 2024
			this.$emit('change', this.value);

			//this.$emit('update:modelValue', this.value);
			this.$emit('update:modelValue', this.value);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		},











		// onInputEl(value)
		// {
		// 	//console.log("onInputEl(): ", value);

		// 	this.$emit('input', value);


		// 	//this.$emit('update:modelValue', value);
		// },

		// onBlurEl($event)
		// {
		// 	// console.log("onBlurEl(): ", $event);

		// 	// this.$emit('blur', $event.target.value);
		// },

		onChangeEl(value)
		{
			//console.log("onChangeEl(): ", value);

			//this.$emit('input', this.value);	//TODO: Disabled 20 Jan 2024
			this.$emit('change', this.value);

			//this.$emit('update:modelValue', this.value);
			this.$emit('update:modelValue', value);
		},


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";


.c-input-select
{
	width: 100%;

	// text-indent: 5px;


	option
	{
		padding: 4px;
	}


	option:checked
	{
		color: rgb(64, 158, 255);
		font-weight: bold;
	}
}

.c-input-select-multiple
{
	// This should cover the first 5 items in the list
	height: 142px;
}
</style>