<template>
	<a :href="href" 
		type="button" 
		:id="id"
		:class="['c-action-link', 'btn', 'btn-outline-primary', 'btn-sm', cssClass]" 
		@click="onClick" 
		:disabled="disabled ? true : false"
		:title="title">
			<span class="icon" v-if="hasIcon()"><slot name="icon"></slot></span>
			<span class="space" v-if="hasIcon() && hasText()"></span>
			<span class="text" v-if="hasText()"><slot name="text"></slot></span>
	</a>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	props: {
		
		/**
		 * The address to navigate to.
		 */
		href: {
			type: String,
			required: true,
			default: ""
		},
	},


	mounted()
	{
		this.displayType = "default";	// "standard" and "default"

		//console.log("c-action-link: this.$slots=", this.$slots);
	},




	methods: {
		hasIcon()
		{
			return this.$slots.icon;
		},

		hasText()
		{
			return this.$slots.text;
		}
	}

}
</script>





<style scoped lang="scss">
	.c-action-link
	{
		border-radius: 4px;
	}
</style>

<style scoped lang="scss">
	.space {
		margin-right: 10px		
	}
</style>