<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="simple-table-continer">
		<table style="width: 100%;" :id="id" class="simple-table">
			<thead>
				<tr>
					<!-- Just testing: the array index of the row -->
					<th class="column-header column-header-style2" v-if="1==2">#</th>

					<!-- Each column in the row. Shown only if it is visible -->
					<template v-for="(fieldMetaData, fieldId, metaIdx) in meta" >
						<th 
							v-if="isVisibleColumn(fieldId, fieldMetaData)"
							:key="'head-' + fieldId"
							type="head"
							:class="['column-header', 'column-header-style2', sortable ? 'sort-by' : '', getColumnAlignmentClass(fieldId)]"
							@click="sortTable(metaIdx, fieldId)"
							:data-field="fieldId"
						>	
							<!-- [jason, 14 Feb 2024]: The title and help cursor style are just really, really quick implementations 
							and not intended to be long term. Longer term I envisage a help icon of sort on the right side to hover and get column help
							if it exists.
							-->
							<span class="text-container" :title="fieldMetaData.titleHelp" :style="{cursor: fieldMetaData.titleHelp ? 'help' : ''}">
								{{ fieldMetaData.columnTitle }}
							</span>

							<span class="resize-handle" v-if="1==2"></span>
						</th>
					</template>

					<!-- "Actions" column: Always available. It currently has no column header. -->
					<th 
						data-field="actions" 
						:class="[getColumnAlignmentClass('actions')]">
						{{ getColumnTitle('actions') }}
					</th>
				</tr>
			</thead>


			<tbody>
				<tr v-for="(item, index) in data" 
					:key="'row-' + index"
				>
						
					<!-- Just testing: the array index of the row -->
					<td :key="'row-col-idx' + index" v-if="1==2">{{ index }}</td>
					
					<!-- Each column in the row. Shown only if it is visible -->
					<template v-for="(fieldMetaData, fieldId) in meta" >
						<td v-if="isVisibleColumn(fieldId, fieldMetaData)"
							:key="'row-col-' + index + '-' + fieldId"
							:class="[getColumnAlignmentClass(fieldId)]"
							:data-field="fieldId"
						>
							<!-- Check if a slot is defined for the column -->
							<slot :name="fieldId" v-bind:item="item"></slot>

							<!-- Fallback to default slot content if no specific slot is provided -->
							<template v-if="!$slots[fieldId]">
								{{ item[fieldId] }}
							</template>
						</td>
					</template>


					<!-- The "actions" column is always available. -->
					<td 
						:key="'actions-' + index"
						:class="[getColumnAlignmentClass('actions')]"
						data-field="actions"
					>
						<!-- It's currently only available via a slot -->
						<slot name="actions" v-bind:item="item"></slot>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td></td>
				</tr>
			</tfoot>
		</table>
	</div>
</template>











<script setup>
import { reactive, computed, onMounted } from "vue";



const props = defineProps({
	id: String,
	data: Array,
	meta: Object, // Each property maps to a 'fields' property. Each is an object: { label: "First Name", control: text, dataType: string, etc.}
	
	// Set to `true` to make the table sortable via clicking on the column header.
	sortable: {
		type: Boolean,
		required: false,
		default: false
	}
});





onMounted(() => {
	init();
});




function init() {
	//TODO: Disabled while trying to developp correcdtly
	//tableColumeResizer();
}










/**
 * A column is visible [by default] if the `visible` property is not set,
 * or, if the property is set and it is `true`.
 * @param {Object} fieldMetaData The meta of a column.
 */
function isVisibleColumn(fieldId, fieldMetaData)
{
	// "Actions" column is a special pre-defined column and not a visible data column.
	if (fieldId == "actions") return false;

	return fieldMetaData.visible == undefined || fieldMetaData.visible
}





function getColumnAlignmentClass(fieldId)	//fieldMetaData) 
{
	//console.log("getColumnAlignmentClass(): fieldMetaData=", fieldMetaData);


	const defaultAlignment = "align-left";



	//-- V1 (osbolete)

	// const align = fieldMetaData.align;

	// if (!align) return defaultAlignment;

	// return "align-" + align;


	//-- V2
	// No meta defined for the special column.
	if (!props.meta[fieldId]) return "";

	var fieldMetaData = props.meta[fieldId];

	// Title is not defined.
	if (!fieldMetaData.align) return defaultAlignment;

	return "align-" + fieldMetaData.align;

}




function getColumnTitle(fieldId) 
{
	//console.log("getColumnAlignmentClass(): fieldMetaData=", fieldMetaData);

	// No meta defined for the special column.
	if (!props.meta[fieldId]) return "";

	var fieldMetaData = props.meta[fieldId];

	// Title is not defined.
	if (!fieldMetaData.columnTitle) return "";


	return fieldMetaData.columnTitle;
}





function sortTable(columnIndex, fieldId)
{
	// If sortable is not set then do not execute.
	if (!props.sortable) return;

	console.log("simple-table.sortTable(): columnIndex", columnIndex, " | fieldId=", fieldId);
	
	//this.$emit('SortColumnIndex', columnIndex - 1);	// Emits in the table-column

	//TODO: Not working, so disabling until I can debug and get it working.
	//sort(columnIndex);
}



function sort(colIndex)
{
	console.log("------ SORT", colIndex);
	//const _this = this;

	sortTable(colIndex, props.id);

	

	// Source: https://www.w3schools.com/howto/howto_js_sort_table.asp
	function sortTable(colIndex, tableId) 
	{
		var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;

		table = document.getElementById(tableId);

		console.log("table=", table);
		switching = true;

		// Set the sorting direction to ascending:
		dir = "asc";

		console.log("table.dir=", dir);

		/* Make a loop that will continue until
		no switching has been done: */
		while (switching) 
		{
			// Start by saying: no switching is done:
			switching = false;
			//rows = table.rows;
			rows = table.tBodies[0].rows;

			//console.log("rows.length===", rows.length);

			/* Loop through all table rows (except the
			first, which contains table headers): */

			for (i = 1; i < (rows.length - 1); i++) 
			{
				// Start by saying there should be no  switching:
				shouldSwitch = false;

				/* Get the two elements you want to compare,
				one from current row and one from the next: */
				x = rows[i].getElementsByTagName("TD")[colIndex];
				y = rows[i + 1].getElementsByTagName("TD")[colIndex];

				//console.log("x[" + i + "] and y[" + (i+1) + "]=", x, y);
				
				/* Check if the two rows should switch place,
				based on the direction, asc or desc: */
				if (dir == "asc") 
				{
					if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) 
					{
						// If so, mark as a switch and break the loop:
						shouldSwitch = true;
						break;
					}
				} 
				else if (dir == "desc") 
				{
					if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) 
					{
						// If so, mark as a switch and break the loop:
						shouldSwitch = true;
						break;
					}
				}
			}

			if (shouldSwitch) 
			{
				/* If a switch has been marked, make the switch
				and mark that a switch has been done: */
				rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
				switching = true;
				// Each time a switch is done, increase this count by 1:
				switchcount ++;
			} 
			else 
			{
				/* If no switching has been done AND the direction is "asc",
				set the direction to "desc" and run the while loop again. */
				if (switchcount == 0 && dir == "asc") 
				{
					dir = "desc";
					switching = true;
				}
			}
		}
	}
}





function tableColumeResizer()
{

	// Our real web app uses Vue.js but I'll stick to plain JavaScript here

	const min = 150;
	// The max (fr) values for grid-template-columns
	const columnTypeToRatioMap = {
		numeric: 1,
		'text-short': 1.67,
		'text-long': 3.33,
	};

	const table = document.querySelector('table');
	/*
	The following will soon be filled with column objects containing
	the header element and their size value for grid-template-columns 
	*/
	const columns = [];
	let headerBeingResized;

	// The next three functions are mouse event callbacks

	// Where the magic happens. I.e. when they're actually resizing
	const onMouseMove = (e) => requestAnimationFrame(() => {
		console.log('onMouseMove');

		if (!headerBeingResized)
		{
			return;	
		}
		
		// Calculate the desired width
		const horizontalScrollOffset = document.documentElement.scrollLeft;
		const width = (horizontalScrollOffset + e.clientX) - headerBeingResized.offsetLeft;
		
		/*
			Update the column object with the new size value
			NOTE: we're only fixing one column's width, not all. This is what causes the bad user experience.
		*/
		const column = columns.find(({ header }) => header === headerBeingResized);

		console.log("column=", column);

		column.size = Math.max(min, width) + 'px'; // Enforce our minimum
		column.header.style.width = width + "px";
		
		
		/* 
			Update the column sizes
			Reminder: grid-template-columns sets the width for all columns in one value
		*/
		table.style.gridTemplateColumns = columns
			.map(({ header, size }) => size)
			.join(' ');
	});

	// Clean up event listeners, classes, etc.
	const onMouseUp = () => {
		console.log('onMouseUp');
		
		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp);
		headerBeingResized.classList.remove('header--being-resized');
		headerBeingResized = null;
	};

	// Get ready, they're about to resize
	const initResize = ({ target }) => {
		console.log('initResize');
		
		headerBeingResized = target.parentNode;
		console.log("initResize(): headerBeingResized=", headerBeingResized, " | target.parentNode=", target.parentNode);
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp);
		headerBeingResized.classList.add('header--being-resized');
	};

	// Let's populate that columns array and add listeners to the resize handles
	document.querySelectorAll('th').forEach((header) => {
		const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
		columns.push({ 
			header, 
			// The initial size value for grid-template-columns:
			size: `minmax(${min}px, ${max})`,
		});

		//console.log("header=", header);

		//header.querySelector('.resize-handle').addEventListener('mousedown', initResize);
		const resizeHandle = header.querySelector('.resize-handle');

		//console.log("  resizeHandle=", resizeHandle);

		if (resizeHandle)
		{
			resizeHandle.addEventListener('mousedown', initResize);
		}

	});
}


</script>











<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	

	.c-simple-table
	{
		
	}

</style>













<!-- Table Column & Header -->

<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.column-header
	{
		font-family: $font-standard;
		// font-size: 12pt;	// Inherit the size, and control changes in implementation from parent
		color: #222f38;
		opacity: .75;
		font-weight: normal;
	}

	//-- Original design. Colour too light and font size too small.
	// font-size: 12px;
	// font-weight: normal;
	// opacity: 0.4;



	.column-header-style2
	{
		color: var(--default-color, #33394A);
		opacity: unset;

		font-size: .8rem;
		font-weight: 700;
	}

	.column-header-style2 .text-container
	{
		width: 100%;
		display: block;

		border-bottom: 1px solid #33394A;
	}




	.align-left
	{
		text-align: left;
	}

	.align-right
	{
		text-align: right;
	}

	.align-center
	{
		text-align: center;
	}
</style>





<style scoped lang="scss">
	.sort-by { 
		padding-right: 18px;
		position: relative;

		cursor: pointer;
	}


	.sort-by:before,
	.sort-by:after {
		border: 4px solid transparent;
		content: "";
		display: block;
		height: 0;
		right: 5px;
		top: 50%;
		position: absolute;
		width: 0;
	}


	.sort-by:before {
		//border-bottom-color: #666;
		border-bottom-color: #fbfafd;
		margin-top: -9px;
	}


	.sort-by:after {
		//border-top-color: #666;
		border-top-color: #fbfafd;
		margin-top: 1px;
	}
</style>









<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

// Source: https://codepen.io/adam-lynch/pen/wbWvep
// See if we can implement something like this.
// Also, checkbox "selectors" down the left side.
//   Adds to a list of selected items that are emitted out in an "selected" element

	table {
		// display: grid;
		// border-collapse: collapse;
		// min-width: 100%;
		// /* These are just initial values which are overriden using JavaScript when a column is resized */
		// grid-template-columns: 
		// minmax(150px, 1fr)
		// minmax(150px, 1.67fr)
		// minmax(150px, 1.67fr)
		// minmax(150px, 1.67fr)
		// minmax(150px, 3.33fr)
		// minmax(150px, 1.67fr)
		// minmax(150px, 3.33fr)
		// minmax(150px, 1.67fr);
	}


	thead,
	tbody,
	tr {
		//display: contents;
	}


	th,
	td {
		padding: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}


	//NOPE: "css table make first column and header sticky": Check out https://stackoverflow.com/questions/15811653/table-with-fixed-header-and-fixed-column-on-pure-css

	//TODO: Not quite, but getting close. z-inde and the opacity of the header and first column cells needs to be sorted out.
	//TODO: testing "css table make column sticky" (https://stackoverflow.com/questions/1312236/how-do-i-create-an-html-table-with-a-fixed-frozen-left-column-and-a-scrollable-b)
	// table thead th:first-child {
	// 	position: sticky;
	// 	left: 0;
	// 	z-index: 400;
	// 	//background: #606060;
	// }
	// table tbody td:first-child {
	// 	position: sticky;
	// 	left: 0;
	// 	//background: white;
	// 	z-index: 100;
	// }

	


	thead, th
	{
		//NOTE: The "padding" of the ".content-area" container in the layout is going to push this down a bit when it's sticky,
		// so we see cell runing behind the header and up above it just before disappearing.
		position: sticky;
		top: 0;
	}

	th {
		// NOTE: I'm using " !important" to override my own styles above while I initially play around and experiment with the design.

		// NOTE: for `position: sticky;` to work the ancestor elements can't have an overflow set.

		//-- I've moved this up to `thead`
		// position: sticky;
		// top: 0;


		// background: #6c7ae0 !important;
		background: #606060;
		//text-align: left !important;
		font-weight: normal !important;
		font-size: 1.1rem !important;
		color: white !important;
		z-index: 200;
	}


	// This is my addition, given the styling in the last `th`
	th.column-header-style2 .text-container
	{
		//-- V1
		//[@jason] I decided it was unnecessaroly busy
		//border-bottom: 1px dashed rgba(255,255,255,0.3);

		//-- V2
		border-bottom: none;
		font-size: .8em;
		font-weight: 600;
	}
	

	th:last-child {
		border: 0;
	}


	.resize-handle {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background: #fbfafd;
		opacity: 0;
		width: 3px;
		cursor: col-resize;
	}


	.resize-handle:hover,
	/* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
	.header--being-resized .resize-handle {
		opacity: 0.5;
	}


	th:hover .resize-handle {
		opacity: 0.3;
	}


	td {
		padding-top: 10px;
		padding-bottom: 10px;
		color: #808080;
	}


	//TODO: Do the hover (next tyle) rather than striped.
	// tr:nth-child(even) td {
	// 	background: #fbfafd;
	// }


	tr:hover
	{
		background: #fbfafd;

		//TOOD: Just playing around with ideas
		// td:first-child::before {
        //     content: '\25B6'; /* Unicode character for right-pointing triangle */
        //     margin-right: 8px;
        //     font-size: .75em; /* Adjust size if needed */
        //     display: inline-block;
        //     vertical-align: middle;
        // }
	}




	// This is mine

	table
	{
		background-color: white;
	}

	// tbody tr
	// {
	// 	border: 1px solid white;
	// }

	// tbody tr:hover
	// {
	// 	border: 1px solid black;
	// }
</style>

























<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	a {
		color: #42b983;
	}



	.simple-table-continer
	{
		//NOTE: `overflow: auto;` will break `position: sticky` on th when scrolling.
		//overflow: auto;

		//.. so, let's see if if we can get away with horizontal overflow/scroll without breaking sticky. 
		// The affect on sticky is untested the time I'm implementing.
		overflow-x: auto;

		//TODO: Not the best or final solution, but it's a start.
		//		Ideally it will be in a parent layout that has a space for the table to expand to fill the available area.
		//height: 600px;
	}


	table {
		width: 100%;
	}

	//.simple-table 
	th 
	{
		white-space: nowrap;

		padding: 10px;
		text-align: left;
		vertical-align: top;
	}

	//.simple-table 
	td 
	{
		// Expando the table
		white-space: nowrap;

		padding: 10px;
		text-align: left;
		vertical-align: top;
	}

</style>