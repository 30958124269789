<template>
	<!-- Single top level item -->
	<div class="single-top-level-item" :index="getItem.id.toString()" :disabled="getItem.url == '' && !item.func" v-if="!getItem.children || getItem.children.length == 0">
		<span :class="{'dropdown-btn': true, 'is-active-outer': getItem.isActive }" @click="onItemClick(getItem)">
			<span class="pr-2">
				<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', 'font-awesome-icon', getItem.faIcon]"></i>
			</span>

			<template v-if="!isCollapsed">
				<span v-if="getItem.html" v-html="getItem.html"></span>
				<span :class="{'is-active': getItem.isActive }">
					{{getItem.text}}
				</span>
			</template>
		</span>
	</div>
	

	<!-- Top level item with Children-->
	<div :index="getItem.id.toString()" v-if="getItem.children && getItem.children.length > 0">
		<!-- Top level item -->
		<div :class="{'dropdown-btn': true, 'is-active-group': getItem.isActiveGroup == true }" @click="expandOrCollapseGroup(getItem.id.toString(), getItem)" >
			<span class="pr-2">
				<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', 'font-awesome-icon', getItem.faIcon]"></i>
			</span>

			<template v-if="!isCollapsed">
				<span v-if="getItem.html" v-html="getItem.html"></span>
				<span>
					{{getItem.text}}
				</span>
			</template>

			<span class="collapsed-group" v-if="getGroupItemState(item) == 'collapsed'"><i class="fa fa-caret-down"></i></span>
			<span class="expanded-group" v-if="getGroupItemState(item) == 'expanded'"><i class="fa fa-caret-up"></i></span>
		</div>
		
		
		<div class="dropdown-container" :style="{ display: getGroupItemDisplay(getItem) }">
			<a class="nav-item sub-nav-item" :index="getItem.id + '-' + child.id" v-for="child in getItem.children" :key="getItem.id + child.id" @click="onItemClick(child)">
				<span :class="{'is-active': child.isActive }">
					<span v-if="child.html" v-html="child.html"></span>
					{{child.text}}
				</span>
			</a>
		</div>
	</div>

</template>







<script>
export default {
	props: [
		'item',
		'isCollapsed'
	],


	//emits: ['expand-group','collapse-group'],
	emits: ['expand-or-collapse-group'],

	data()
	{
		return {
			indexCount: 0,


			expandCollapseState: {}
		};
	},



	computed: {
		/**
		 * Return the passed in item. Return via a computed so "undefined" during render is handled gracefully.
		 */
		getItem()
		{
			if (this.item == undefined) return {
				children: []
			};

			return this.item;
		},


		getModule()
		{
			if (this.item == undefined) return '';

			if (this.item.module) return this.item.module;

			return '';
		},

		// getIsModuleReturnClass()
		// {
		// 	if (this.getModule == "--module-return--") return 'module-return-item';

		// 	return '';
		// }
	},


	methods: {
		getNextIndexCount()
		{
			this.indexCount++;
			return this.indexCount;
		},
		iconClick()
		{
			this.$root.redirectToPath(this.getItem.url);
		},


		onItemClick(item)
		{
			//console.log("onItemClick(): item=", item);

			if (item.url)
			{
				//console.log("redirect");
				this.$root.redirectToPath(item.url);
			}

			if (item.func)
			{
				//console.log("execute func");
				item.func();
			}
		},


		/**
		 * Set the "new" (change to) expanded or collapsed state of the target item group,
		 * and raise and event to the `primary-side-nav`.
		 */
		expandOrCollapseGroup(groupItemId, item)
		{
			const currentState = this.expandCollapseState[groupItemId];
			if (!currentState)
			{
				// Not set yet.
				// 1. Collapsed by default, so this is the first expand. 
				// 2. Unless it is the group containing the active item, then it is expanded by default.

				if (item.isActiveGroup)
				{
					// Is the active item group, so expanded by default
					this.expandCollapseState[groupItemId] = 'collapsed';
					this.$emit('expand-or-collapse-group', { toState: 'collapsed', groupItemId: groupItemId, item: item });
					return;					
				}

				// Not the active item group
				this.expandCollapseState[groupItemId] = 'expanded';
				this.$emit('expand-or-collapse-group', { toState: 'expanded', groupItemId: groupItemId, item: item });
				return;
			}

			if (currentState == 'expanded')
			{
				// Currently expanded, so collapse it.
				this.expandCollapseState[groupItemId] = 'collapsed';
				this.$emit('expand-or-collapse-group', { toState: 'collapsed', groupItemId: groupItemId, item: item });
				return;
			}

			// Currently collapsed, sp exand it.
			this.expandCollapseState[groupItemId] = 'expanded';
			this.$emit('expand-or-collapse-group', { toState: 'expanded', groupItemId: groupItemId, item: item });
			
		},




		getGroupItemState(item)
		{

			//console.log("   getGroupItemState(): item.groupExpandedState=", item.groupExpandedState, " | item=", item);

			// State has been explicitly set.
			// State is set as explicitly "collapsed", even if it is the group with the current active item.
			if (item.groupExpandedState != undefined && item.groupExpandedState == "collapsed")
			{
				//console.log("   getGroupItemState(): 1");
				return "collapsed";
			}

			// Group contains the active page item, so must be expanded (if not explicitly collapsed by the user).
			if (item.isActiveGroup) 
			{
				//console.log("   getGroupItemState(): 2");
				return "expanded";
			}


			//-- Now check if the menu even has an expanded state triggered by the user.

			// Not yet set. Collapsed by default.
			if (!item.groupExpandedState)
			{
				//console.log("   getGroupItemState(): 3");
				return "collapsed";
			}

			// State has been set by the user. Return it.
			//console.log("   getGroupItemState(): 4");
			return item.groupExpandedState
		},

		getGroupItemDisplay(item)
		{
			if (this.getGroupItemState(item) == 'expanded') return 'block';

			// Otherwise, collapsed.
			return 'none';
		}
		
	}
}
</script>





<style lang="scss" scoped>
	.dropbown-btn:hover .font-awesome-icon,	// Not working
	.dropdown-btn:hover
	{
		color:#FDB515;
	}


	/* The active item for the current route */

	.is-active-outer, // Outer span for the top level item
	.is-active
	{
		color:#FDB515;
	}
	.is-active
	{
		// Text span for any item
		text-decoration: underline;
	}

	/* The group title with the active item for the current route */
	.is-active-group
	{
		color:#FDB515;
	}



	


	//-- Overrides for the special "module return" nav item.
	// .module-return-item
	// {
	// 	margin-right: 1.5rem;
	// }

	// .module-return-item .nav-item-text
	// {
	// 	font-weight: bold;
	// 	// color: navy;
	// 	font-style: italic;
	// 	// text-decoration: underline;

	// 	color:#FDB515;
	// }
	// .module-return-item .nav-item-text:hover
	// {
	// 	color:#576874;
	// }

	// .module-return-item .fa-icon
	// {
	// 	// height: 22px;
	// 	// width: 22px;
	// 	// display: inline-block;


	// 	// .svg-inline--fa
	// 	// {
	// 	// 	height: 1.6em;		//20 Oct 2020 [JS]: Just a rushed eyeball approximation.	//TODO: Review and fix
	// 	// }

	// 	color:#FDB515;

	// }
	// .module-return-item .fa-icon:hover
	// {
	// 	color:#576874;
	// }
</style>