<template>
	<span class="menu-item-icon">
		<i class="fas fa-th-large"></i>
	</span>
</template>




<script>
export default {

}
</script>




<style>
.menu-item-icon
{
	padding-right: 10px;
}
</style>