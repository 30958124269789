<template>
	<div class="row sub-navigation-container">
		<div class="col-12" id="account-admin-navigation-container" v-if="layoutType == 'account-admin'">
			<el-row>
				<el-col>
					<el-menu
						:default-active="activeItem"
						active-text-color="#ffb600"
						background-color="#4D5670"
						text-color="#fff"
						id="account-admin-navigation-menu"
					>
						<el-menu-item index="details" @click="itemClickedAccountAdmin('details')" v-if="isDevelopment() || isLotusAccount()">
							<menu-item-icon></menu-item-icon>
							<span>Details</span>
						</el-menu-item>

						<el-menu-item index="users" @click="itemClickedAccountAdmin('users')" :disabled="isCreatingNewAccount">
							<menu-item-icon></menu-item-icon>
							<span>Users</span>
						</el-menu-item>

						<el-menu-item index="settings" @click="itemClickedAccountAdmin('settings')" :disabled="isCreatingNewAccount" v-if="isDevelopment() || isLotusAccount() || isLotusAlias()">
							<menu-item-icon></menu-item-icon>
							<span style="padding-right: 6px;">Settings</span>
							<lotus-only></lotus-only>
						</el-menu-item>
					</el-menu>
				</el-col>
			</el-row>

		</div>



		<div class="col-12" id="manage-navigation-container" v-if="layoutType == 'manage'">
			<el-row>
				<el-col>
					<el-menu
						:default-active="activeItem"
						active-text-color="#ffb600"
						background-color="#4D5670"
						text-color="#fff"
						id="manage-navigation-menu"
					>
						<!-- "Quoting" related items -->
						<el-menu-item index="jobs" @click="itemClickedManage('jobs')" v-if="$root.hasAccess('Quoting.All')">
							<menu-item-icon></menu-item-icon>
							<span>Jobs</span>
						</el-menu-item>

						<el-menu-item index="quotes" @click="itemClickedManage('quotes')" v-if="$root.hasAccess('Quoting.All')">
							<menu-item-icon></menu-item-icon>
							<span>Quotes</span>
						</el-menu-item>
						


						<!-- Share "Quoting" and "Timesheets" related items -->
						<el-menu-item index="clients" @click="itemClickedManage('clients')" :disabled="isCreatingNewAccount" v-if="$root.hasAccess('Quoting.All') || $root.hasAccess('Timesheets.Enabled')">
							<menu-item-icon></menu-item-icon>
							<span>Clients</span>
						</el-menu-item>



						<!-- "Timesheets" related items -->
						<el-menu-item index="buckets" @click="itemClickedManage('buckets')" :disabled="isCreatingNewAccount" v-if="$root.hasAccess('Timesheets.Enabled')">
							<menu-item-icon></menu-item-icon>
							<span>Buckets</span>
						</el-menu-item>



						<!-- The following are Lotus only at the moment -->
						<el-menu-item index="addresses" @click="itemClickedManage('addresses')" v-if="isDevelopment() || isLotusAccount()">
							<menu-item-icon></menu-item-icon>
							<span style="padding-right: 6px;">Addresses</span>
							<lotus-only></lotus-only>
						</el-menu-item>
						
						<el-menu-item index="organisations" @click="itemClickedManage('organisations')" v-if="isDevelopment() || isLotusAccount()">
							<menu-item-icon></menu-item-icon>
							<span style="padding-right: 6px;">Organisations</span>
							<lotus-only></lotus-only>
						</el-menu-item>

						<el-menu-item index="people" @click="itemClickedManage('people')" v-if="isDevelopment() || isLotusAccount()">
							<menu-item-icon></menu-item-icon>
							<span style="padding-right: 6px;">People</span>
							<lotus-only></lotus-only>
						</el-menu-item>



						<!-- //TODO: Remove this once keywords are integrated/linked under buckets -->
						<el-menu-item index="keywords" @click="itemClickedManage('keywords')" :disabled="isCreatingNewAccount" v-if="isDevelopment() && 1==2">
							<menu-item-icon></menu-item-icon>
							<span>Keywords</span>
						</el-menu-item>

					</el-menu>
				</el-col>
			</el-row>
		</div>
	</div>
</template>







<script>
import MenuIteIcon from './menu-item-icon.vue';

export default {
	components: {
		'menu-item-icon': MenuIteIcon
	},





	props: {
		accountId: {
			type: String,
			required: true
		},


		layoutType: {
			type: String,
			required: true,
			default: "default"		//-- "manage", "account-admin", "default"
		}
	},

	mounted() 
	{
		this.init();
	},

	data() 
	{
		return {
			activeItem: "details",	// Default selected item

			isCreatingNewAccount: false
		};
	},

	methods: 
	{
		init()
		{
			// Account Admin
			this.checkIfCreatingNewAccount();


			// All sub-navs
			this.setActiveItemFromRoute();
		},



		checkIfCreatingNewAccount()
		{
			// If we're creating a new account as system admin. We need to disable most menu items until created.
			this.isCreatingNewAccount = (this.$route.query.newAccount && this.$route.query.newAccount != "");
		},



		itemClickedAccountAdmin(item)
		{
			//alert(item);

			//var routeAccountId = this.$root.getRouteValue("accountId");

			//console.log("itemClickedAccountAdmin(): this.accountId=", this.accountId);

			item = item.toLowerCase();

			if (item == "details") { this.redirect("/account-admin/" + this.accountId); return; }

			if (item == "settings") { this.redirect("/account-admin/" + this.accountId + "/settings"); return; }

			if (item == "users") { this.redirect("/account-admin/" + this.accountId + "/users"); return; }

			if (item == "settings") { this.redirect("/account-admin/" + this.accountId + "/settings"); return; }

			//alert("Unknown action");
		},

		itemClickedManage(item)
		{
			//alert(item);

			//var routeAccountId = this.$root.getRouteValue("accountId");

			//console.log("itemClickedManage(): this.accountId=", this.accountId);

			item = item.toLowerCase();

			if (item == "index") { this.redirect("/manage/"); return; }

			if (item == "jobs") { this.redirect("/manage/jobs"); return; }

			if (item == "addresses") { this.redirect("/manage/addresses"); return; }

			if (item == "organisations") { this.redirect("/manage/organisations"); return; }

			if (item == "people") { this.redirect("/manage/people"); return; }

			if (item == "quotes") { this.redirect("/manage/quotes"); return; }

			if (item == "clients") { this.redirect("/manage/" + this.accountId + "/clients"); return; }

			// if (item == "buckets") { this.redirect("/manage/" + this.accountId + "/buckets"); return; }
			if (item == "buckets") { this.redirect("/manage/" + this.accountId + "/buckets"); return; }
			// if (item == "bucket-tasks") { this.redirect("/manage/" + this.accountId + "/bucket/tasks"); return; }
			if (item == "keywords") { this.redirect("/manage/" + this.accountId + "/bucket/keywords"); return; }
		},



		setActiveItemFromRoute()
		{
			var _this = this;


			/**
			 * Check if the current route path matches the specified one.
			 */
			function isCurrentPath(checkPath)
			{
				//var path = _this.$router.currentRoute.path;
				//path = path.toLowerCase();
				let path = _this.$root.getCurrentRoutePath();

				checkPath = checkPath.toLowerCase();
				checkPath = checkPath.replace(":accountid", _this.accountId);

				if (path == checkPath) 
				{
					//console.log("isCurrentPath(" + checkPath + "): TRUE | path=", path);
					return true;
				}

				//console.log("isCurrentPath(" + checkPath + "): FALSE | path=", path);

				return false;
			}


			/**
			 * Check if the route name of the current page is the provided name.
			 */
			function isCurrentRouteByName(routeName)
			{
				//var currentRouteName = _this.$router.currentRoute.name;
				const currentRouteName = _this.$root.getCurrentRouteName();

				return (currentRouteName.toLowerCase() == routeName.toLowerCase());
			}


			//-- "Account Admin" sub-nav
			if (this.layoutType == "account-admin")
			{
				//console.log("setActiveItemFromRoute(): this.accountId=", this.accountId);
				
				// if (isCurrentPath("/account-admin/:accountId")) this.activeItem = "details";
				// if (isCurrentPath("/account-admin/:accountId/users")) this.activeItem = "users";
				// if (isCurrentPath("/account-admin/:accountId/clients")) this.activeItem = "clients";
				// if (isCurrentPath("/account-admin/:accountId/buckets")) this.activeItem = "buckets";
				// if (isCurrentPath("/account-admin/:accountId/bucket/:bucketId/tasks")) this.activeItem = "bucket-tasks";
				// if (isCurrentPath("/account-admin/:accountId/bucket/keywords")) this.activeItem = "bucket-keywords";
				// if (isCurrentPath("/account-admin/:accountId/bucket/rates")) this.activeItem = "bucket-rates";
				// if (isCurrentPath("/account-admin/:accountId/settings")) this.activeItem = "settings";
				

				if (isCurrentRouteByName("account-admin-home")) this.activeItem = "details";

				if (isCurrentRouteByName("account-admin-settings")) this.activeItem = "settings";

				if (isCurrentRouteByName("account-admin-users")) this.activeItem = "users";
				if (isCurrentRouteByName("account-admin-user-edit")) this.activeItem = "users";
				if (isCurrentRouteByName("account-admin-user-access")) this.activeItem = "users";
				if (isCurrentRouteByName("account-admin-user-new")) this.activeItem = "users";

				// if (isCurrentRouteByName("account-admin-settings")) this.activeItem = "settings";
			}



			//-- "Manage" sub-nav
			if (this.layoutType == "manage")
			{
				if (isCurrentRouteByName("manage-home")) 
				{
					//this.activeItem = "index";
					this.redirect("/manage/jobs");
				}

				if (isCurrentRouteByName("manage-jobs")) this.activeItem = "jobs";
				if (isCurrentRouteByName("manage-jobs-edit")) this.activeItem = "jobs";
				if (isCurrentRouteByName("manage-jobs-new")) this.activeItem = "jobs";

				if (isCurrentRouteByName("manage-addresses")) this.activeItem = "addresses";
				if (isCurrentRouteByName("manage-address-edit")) this.activeItem = "addresses";
				if (isCurrentRouteByName("manage-addresses-new")) this.activeItem = "addresses";


				if (isCurrentRouteByName("manage-organisations")) this.activeItem = "organisations";
				if (isCurrentRouteByName("manage-organisation-edit")) this.activeItem = "organisations";
				if (isCurrentRouteByName("manage-organisations-new")) this.activeItem = "organisations";


				if (isCurrentRouteByName("manage-people")) this.activeItem = "people";
				if (isCurrentRouteByName("manage-person-edit")) this.activeItem = "people";
				if (isCurrentRouteByName("manage-people-new")) this.activeItem = "people";


				if (isCurrentRouteByName("manage-quotes")) this.activeItem = "quotes";
				if (isCurrentRouteByName("manage-quote-edit")) this.activeItem = "quotes";
				if (isCurrentRouteByName("manage-quotes-new")) this.activeItem = "quotes";


				if (isCurrentRouteByName("manage-clients")) this.activeItem = "clients";
				if (isCurrentRouteByName("manage-clients-edit")) this.activeItem = "clients";
				if (isCurrentRouteByName("manage-clients-new")) this.activeItem = "clients";

				if (isCurrentRouteByName("manage-buckets")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-edit")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-new")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-tasks")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-task-edit")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-task-new")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-keywords")) this.activeItem = "buckets";
				if (isCurrentRouteByName("manage-bucket-rates")) this.activeItem = "buckets";


				//TODO: Nope
				// if (isCurrentRouteByName("manage-contact-methods")) this.activeItem = "contact-methods";
				// if (isCurrentRouteByName("manage-contact-method-edit")) this.activeItem = "contact-methods";

				// if (isCurrentRouteByName("manage-business-identifiers")) this.activeItem = "business-identifiers";
				// if (isCurrentRouteByName("manage-business-identifier-edit")) this.activeItem = "business-identifiers";	
			}


		},


		redirect(url)
		{
			//this.$root.pushToPath(url);
			//this.$root.redirectToPath(url);		// Redirecting at the moment because "push" doesn't update the page title.
			this.$root.pushToPath(url);
			//console.log("sub-navigation.redirect(): url=" +  url);
		},



		isDevelopment()
		{
			return this.$root.isDevelopment();
		},




		isLotusAccount()
		{
			return this.$root.isLotusAccount();
		},


		isLotusAlias()
		{
			return this.$root.isLotusAlias();
		},


	},
};
</script>






<style scoped lang="scss">

// There's a default right border from Element UI [I think, unless I added it and don't realise] for some reason. Get rid of it.
#account-admin-navigation-menu
{
	border-right: none;
}

// There's a default right border from Element UI [I think, unless I added it and don't realise] for some reason. Get rid of it.
#manage-navigation-menu
{
	border-right: none;
}
</style>


<style lang="scss">
.sub-navigation-container .el-menu-item
{
	font-weight: bold;

	line-height: 1em;	/* Override the el-menu-item line-height */
}



</style>