<template>
	<div class="row primary-top-nav" style="margin-left: 0; margin-right: 0;">
		<!-- //TODO: Review - Remove margins to it doesn't extend outside parent (eg. Practitioner layout) -->

		<!-- <div class="selected-module-name">{{ selectedModuleName }}</div> -->

		<div class="col-12 nav-header" v-if="context == 'standard'">
			<div class="desktop-nav">
				<primary-top-nav-item-desktop v-for="item in getItems" :key="item.id" v-bind:item="item"></primary-top-nav-item-desktop>
			</div>
			<div class="mobile-nav">
				<div class="nav-menu" @click="toggleMenu">
					<div class="nav-menu-icon" v-show="!isMobileMenuExpended">Menu</div>
					<div class="nav-menu-icon closemenu" v-show="isMobileMenuExpended">Menu</div>
				</div>
			</div>


			<!-- Show only on MD and above -->
			<div class="d-none d-md-block">
				<!-- isDevelopment(): Hide from deployed environments until I've written the KBs properly [jsnekders, 2022-03-18] -->
				<!-- //TODO: [JS] Temporary disabled for sync with production -->
				<div class="nav-item nav-item-help nav-item-right" title="Help for this page (opens in new tab)" v-if="canAccessKnowledgebase() && isDevelopment()">
					<a :href="helpUrl" style="color: inherit" target="_blank">
						<i class="fas fa-question"></i>
					</a>
				</div>
				
				<!-- isDevelopment(): Hide from deployed environments until I've finished developing it [jsnekders, 2022-03-18] -->
				<!-- //TODO: [JS] Temporary disabled for sync with production -->
				<div class="nav-item nav-item-help nav-item-right" title="View background tasks that have run" v-if="canAccessBackgroundTasks()">
					<a href="/background-tasks" style="color: inherit">
						<i class="fas fa-bell"></i>
					</a>
				</div>

			</div>
			<!-- Show only on LG and above -->
			<div class="d-none d-lg-block nav-item nav-item-account nav-item-right ttp" title="My Details">
				<a href="/my-profile" style="color: inherit">
					{{currentUserInitials}}
				</a>
			</div>
		</div>



		<div class="col-12 nav-header" v-if="context == 'mobile-app'">
			<div class="desktop-nav">
				<primary-top-nav-item-desktop v-for="item in getItems" :key="item.id" v-bind:item="item"></primary-top-nav-item-desktop>
			</div>
			<div class="mobile-nav">
				<div class="nav-menu">
					<div class="nav-menu-icon">Menu</div>
				</div>
			</div>
		</div>
		

		<div class="mobile-nav-items" v-show="isMobileMenuExpended">
			<!-- Show only on MD and lower -->
			<ul class="d-block d-lg-none">
				<primary-top-nav-item-mobile v-for="item in getItems" :key="item.id" v-bind:item="item"></primary-top-nav-item-mobile>

				<li class="nav-item" v-if="context == 'standard'">
					<span class="container">
						<a href="/my-profile">
							My Details
						</a>
					</span>
				</li>
			</ul>
		</div>

		<!-- selectedModule: {{ selectedModule }} -->

	</div>
</template>


<script>
import globalStore from "/globalStore";


export default {

	props: {
		// What sort of page/layout context this is running in.
		// Options: 'standard', 'mobile-app'
        context: {
			type: String,
			required: false,
            default: "standard"
		},
	},




	
	data()
	{
		return {
			globalStore: globalStore,

			isMobileMenuExpended: false,

			itemCount: 0,
			items: [],

			isLoadingMenu: true,

			currentUserInitials: "",

			isSystemAdmin: false,

			selectedModule: "",
			selectedModuleStorageKey: "PrimaryTopNav:SelectedModile",

			helpUrl: "/kb",


			modules: {
				global: { Name: "Global" },
				timesheets: { Name: "Timesheets" },
				quoting: { Name: "Quoting" },
				tessta: { Name: "Tessta" },
				tesstaClients: { Name: "Tessta Client Portal" },
				rostering: { Name: "Rostering" },
				lotus: { Name: "Lotus" },
			},
		};
	},





	computed: {
		getItems()
		{
			
			// Wait until loading of menu items is complete. Null stripping needs to happen.
			if (this.isLoadingMenu == true) return [];


			//console.log("getItems(): ", this.items);
			return this.items;
		},


		selectedModuleName()
		{
			var name = this.modules[this.selectedModule]?.Name;

			if (!name) return "";

			return name;
		}
	},




	mounted()
	{
		this.init();


		//console.log("primary-top-nav: (" + this.$route.name + ") this.$route=", this.$route);
	},





	methods: {



		init()
		{
			// System Admin check
			this.isSystemAdmin = this.$root.isSystemAdminUser();

			// Get the last set module from local storage
			this.selectedModule = globalStore.localStorage.getDataFromStorage(this.selectedModuleStorageKey);
			if (!this.selectedModule) this.selectedModule = '';

			this.loadCurrentUserInitials();

			this.initialiseMenu();
		},





		initialiseMenu()
		{
			this.items = [];


			//TODO: Scenario to handle:
			//		We switch modules in the men - that's fine.
			//		Then navigate to a page (full page refresh) which is going to lose the selected module.
			//		We need to remember the selected module through refreshes (e.g localStorage)
			//		Always assume a page refresh can happen (even if we eventually get most pages into true SPA routes without refresh).



			if (this.context == 'standard')
			{
				this.loadItemsForStandard();
			}
			if (this.context == 'mobile-app')
			{
				this.loadItemsForMobileApp();
			}


			// Find any child arrays with a null value in an index, and remove.
			this.stripNullChildren();



			this.filterItemsToSelectedModile();


			// Only after null stripping.
			this.isLoadingMenu = false;



			//this.initMobileMenu();	//TODO: Obsolete
		},







		filterItemsToSelectedModile()
		{			
			var moduleReturn = this.items.find(m => m.module == "--module-return--");
			var selectedModuleItem = this.items.find(m => m.module == this.selectedModule);

			if (!moduleReturn || !selectedModuleItem)
			{
				// One of them was not found. That's wrong. Continue to show the full menu.
				return;
			}

			// Add the module return.
			this.items = [moduleReturn];

			// Show just the children of the selected module.
			this.items = this.items.concat(selectedModuleItem.children);
		},










		/**
		 * Load the menu items for pages embedded within the mobile app.
		 */
		loadItemsForMobileApp()
		{
			// this.addItemFaIcon("Home", "fas fa-home", "/home", []);


			
			//-- Manage: Jobs, Tasks and Scheduling
			if(this.$root.hasAccess('AccountAdmin.All') || this.$root.hasAccess('ManageAdmin.All') || this.$root.hasAccess('GeneralAdministrator.All'))
			{
				this.addItemFaIcon("New Job", "fas fa-plus", "/mobile-app/new-job", []);

				this.addItemFaIcon("Manage Jobs", "fas fa-tasks", "/mobile-app/manage-jobs", []);
			}

		},










		/**
		 * Load the menu items for pages viewed within the standard portal in a browser.
		 */
		loadItemsForStandard()
		{
			// const route = this.$route;
			// let module = route.meta.module ? route.meta.module : "";
			// //console.log("nav(): module=", module, " | route=", route);
			//
			// module = module.toLowerCase();



			//TODO: Obsolete
			// if (module == "tessta" && this.$root.hasAccess('Administration.Administration'))		//TODO: Change this to 'Tessta.All'
			// {
			// 	this.loadItemsForStandard_tessta();
			// }
			// else
			// {
			// 	// Original
			// 	this.loadItemsForStandard_original();
			// }


			this.loadItemsForStandard_original();
		},

		







		//TODO: Obsolete
		// loadItemsForStandard_tessta()
		// {

		// 	// //----- Testing: Go back a level in the nav -----
		// 	// //<i class="fa-solid fa-turn-up"></i> <i class="fas fa-level-up-alt"></i>
		// 	// function changeModuleHandler()
		// 	// {
		// 	// 	alert("Change module");
		// 	// }
		// 	// this.addItemFaIcon("Change Module", "fas fa-level-up-alt", "", [], changeModuleHandler);




			

		// 	this.addItemFaIcon("Home", "fas fa-home", "/home", []);

		// 	this.addItemFaIcon("Clients", "far fa-address-book", "/module/tessta/clients", []);

		// 	this.addItemFaIcon("Sites", "fas fa-building", "/module/tessta/sites", []);

		// 	this.addItemFaIcon("Projects", "fas fa-tasks", "/module/tessta/projects", []);

		// 	this.addItemFaIcon("QA", "fas fa-check-double", "/module/tessta/qa", []);

		// 	this.addItemFaIcon("Config", "fas fa-cogs", "/module/tessta/configuration", []);



		// },










		changeModuleHandler(module)
		{
			//TODO: Disabled until cross-page module remembering implemented.
			//return;

			console.log("changeModuleHandler(): module=", module);
			this.selectedModule = module;

			// Save the selection to local storage
			globalStore.localStorage.saveDataToStorage(this.selectedModuleStorageKey, this.selectedModule);

			this.initialiseMenu();
		},


		







		loadItemsForStandard_original()
		{
			var _this = this;




			//----- Testing: Go back a level in the nav -----
			if (this.selectedModule != "")
			{
				//<i class="fa-solid fa-turn-up"></i> <i class="fas fa-level-up-alt"></i>
				this.addModuleRootItemFaIcon('--module-return--', this.selectedModuleName, "fas fa-level-up-alt", "", [], () => { _this.changeModuleHandler(''); });
			}








			//----- HOME -----
			//TDOO: Disable until we have an actual page.
			function homeItems()
			{
				// All users can access this page
				_this.addItemFaIcon("Home", "fas fa-home", "/home", []);
			}
			//homeItems();















			//console.log("!! Acces Test: _this.$root.hasAccess('AccountAdmin.All')=", _this.$root.hasAccess('AccountAdmin.All'));

			
			//----- [Global] Manage: Jobs, Tasks and Scheduling -----
			function globalModule()
			{
				if (_this.$root.hasAccess('TesstaClients.ClientPortal'))
				{
					// Don't show it for the client portal.
					//TODO: This needs additional permissions.
					return;
				}
				//TODO: isDevelopment() while under development.
				// if( !(_this.$root.hasAccess('AccountAdmin.All') || _this.$root.hasAccess('ManageAdmin.All') || _this.$root.hasAccess('GeneralAdministrator.All')) )
				// {
				// 	return;
				// }


				// _this.changeModuleHandler('');
				// this.addItemFaIcon("Modules", "fas fa-level-up-alt", "", [], _this.changeModuleHandler(''));

				//_this.addItemFaIcon("Manage", "fas fa-tasks", "/manage/jobs", []);


				if(!_this.$root.hasAccess('AccountAdmin.All') && !_this.$root.hasAccess('LotusAdministration.All'))
				{
					// Doesn't have any of the required permissions. Don't show the top level item.
					return;
				}


				var accountId = _this.globalStore.state.currentUser.activeAccountId;

				let accountAdminItem = null;
				if(_this.$root.hasAccess('AccountAdmin.All'))
				{
					accountAdminItem = _this.getItemFaIcon("Account Admin", "fas fa-user-cog", "/account-admin/" + accountId, []);
				}


				let manageItem = null;
				if(_this.$root.hasAccess('LotusAdministration.All'))	//TODO: Restricting for the moment
				{
					manageItem = _this.getItemFaIcon("Manage", "fas fa-tasks", "/manage/jobs", []);
				}


				_this.addModuleRootItemFaIcon('global', "Global", "fas fa-globe", "", 
					[
						manageItem,
						accountAdminItem
					],
					() => { _this.changeModuleHandler('global'); }
				);
			}
			globalModule();




			//TODO: Obsolete. See "Global".
			// //----- Account Administration -----
			// function accountAdministration()
			// {
			// 	//if( _this.$root.hasAccess('AccountAdmin.All'))
			// 	var accountId = _this.globalStore.state.currentUser.activeAccountId;

			// 	if(!_this.$root.hasAccess('AccountAdmin.All'))
			// 	{
			// 		return;
			// 	}

			// 	// _this.addItemCustomIcon("Account Admin", "icon-dashboard", "/account-admin/" + accountId, [
			// 	// 	{ id: 1, text: "Users", url: "/account-admin/" + accountId  + "/users"},
			// 	// 	{ id: 2, text: "Buckets", url: "/account-admin/" + accountId  + "/buckets"},
			// 	// 	{ id: 3, text: "Rates", url: "/account-admin/" + accountId  + "/rates"},
			// 	// ]);

			// 	// _this.addItemCustomIcon("Account Admin", "icon-dashboard", "/account-admin/" + accountId, []);
			// 	_this.addItemFaIcon("Account Admin", "fas fa-user-cog", "/account-admin/" + accountId, []);
			// }
			// accountAdministration();

			



			//-- Timesheets
			function timesheetsModule()
			{
				// if(!_this.$root.hasAccess('LotusAdministration.All'))	//TODO: Restricting for the moment
				// {
				// 	return;
				// }
				
				if (!_this.$root.hasAccess('Timesheets.Enabled'))		//TODO: The standard check
				{
					return;
				}


				//var timesheetPages = [];

				//TODO: 18 July 2021. Disabled until we have actual timesheet pages for the user. Currently /timesheet just redirects to the /home page (original /timesheet page is now no more).
				// if( _this.$root.hasAccess('Timesheet.Owner'))
				// {
				// 	//_this.addItemCustomIcon("Timesheets", "icon-timesheets", "/timesheet", []);
				// 	timesheetPages.push({ id: 1, text: "My Timesheets", url: "/timesheet"});
				// 	// timesheetPages.push({ id: 2, text: "Create Timesheet", url: "/module/timesheets/create"});
				// }




				//TODO: Update this when ready for production.
				// if (_this.isProduction() == false)		
				// {
				// 	if (_this.$root.hasAccess('Timesheet.Manager'))
				// 	{
				// 		timesheetPages.push({ id: 3, text: "Outstanding Timesheets", url: "/module/timesheets/manager/outstanding-timesheets"});
				// 		timesheetPages.push({ id: 4, text: "Timesheet Report", url: "/module/timesheets/manager/timesheets-report"});
				// 	}
				// }
				

				// if (timesheetPages.length > 0)
				// {
				// 	_this.addItemCustomIcon("Timesheets", "icon-timesheets", "#", timesheetPages);
				// }


				var accountId = _this.globalStore.state.currentUser.activeAccountId;

				var children = [];
				children.push(_this.getItemFaIcon("Home", "far fa-file-alt", "/home/timesheets", []));

				children.push(_this.getItemFaIcon("Buckets", "far fa-file-alt", "/manage/" + accountId + "/buckets", []));
				children.push(_this.getItemFaIcon("Clients", "far fa-file-alt", "/manage/" + accountId + "/clients", []));

				//TODO: Currently under development
				let bucketsReportItem = null;
				if (_this.$root.hasAccess('LotusDevelopment.All'))
				{
					// Still in development
					bucketsReportItem = _this.getItemCustomIcon("Buckets Report", "icon-projects", "/module/timesheets/buckets-report", []);
					children.push(bucketsReportItem);
				}

				//TODO: Currently under development
				let newKeywordsItem = null;
				if (_this.$root.hasAccess('LotusDevelopment.All'))
				{
					// Still in development
					newKeywordsItem = _this.getItemFaIcon("Keywords", "far fa-file-alt", "/module/timesheets/keywords/new-detected", []);
					children.push(newKeywordsItem);
				}


				_this.addModuleRootItemCustomIcon('timesheets', "Timesheets", "icon-timesheets", "", 
					children,
					() => { _this.changeModuleHandler('timesheets'); }
				);
			}
			timesheetsModule();



			// //----- Timesheet Reports (was Buckets) -----
			// function timesheetReportsItems()
			// {
			// 	//TODO: @jason, 18 Oct 2022: Disabled until we need it and plan out how to go forward with it.
			// 	const isDisabled = true;
			// 	if (isDisabled) return;


			// 	//TODO: Disabled during initial development (while deploying to production)
			// 	//if( _this.$root.hasAccess('AccountAdmin.All'))	//TODO: Restricting while developing
			// 	if(!_this.$root.hasAccess('LotusAdministration.All'))	//TODO: Restricting while developing
			// 	{
			// 		return;
			// 	}

			// 	// _this.addItemCustomIcon("Buckets", "icon-projects", "#", [
			// 	// 	{ id: 1, text: "Buckets in Account", url: "/buckets"},
			// 	// ]);
			// 	_this.addItemCustomIcon("Reports", "icon-projects", "/buckets", []);		//TODO: Previously "Buckets"
			// }
			// timesheetReportsItems();



			// //---- Keywords -----
			// function timesheetKeywordsItems()
			// {
			// 	if(!_this.$root.hasAccess('LotusDevelopment.All'))	//TODO: Restricting while developing
			// 	{
			// 		return;
			// 	}
			// 	// _this.addItemCustomIcon("Buckets", "icon-projects", "#", [
			// 	// 	{ id: 1, text: "Buckets in Account", url: "/buckets"},
			// 	// ]);
			// 	_this.addItemFaIcon("Keywords", "far fa-file-alt", "/module/timesheets/keywords/new-detected", []);
			// }
			// timesheetKeywordsItems();

			



			//---- Quoting module -----
			function quotingModule()
			{	
				// Quoting dashboard.
				if (!_this.$root.hasAccess('Quoting.All'))
				{
					return;
				}


				var accountId = _this.globalStore.state.currentUser.activeAccountId;

				_this.addModuleRootItemFaIcon('quoting', 'Quoting', 'far fa-file-alt', '', 
					[
						_this.getItemFaIcon("Home", "fas fa-home", "/module/quotes", []),		// Also: /home/quoting (get rid of that route)
						_this.getItemFaIcon("Clients", "far fa-file-alt","/manage/" + accountId + "/clients", []),
						_this.getItemFaIcon("Manage Quotes", "far fa-file-alt", "/module/quotes/manage", []),
						_this.getItemFaIcon("New quote", "far fa-file-alt", "/module/quotes/new/select-client", []),
					],
					() => { _this.changeModuleHandler('quoting'); }
				);
			}
			quotingModule();

			



			//----- Module: Tessta -----
			function tesstaModule()
			{
				if (!_this.$root.hasAccess('Tessta.Administration'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('tessta', "Tessta Admin", "fas fa-road", "", 
					[
						_this.getItemFaIcon("Home", "fas fa-home", "/module/tessta", []),
						_this.getItemFaIcon("Clients", "far fa-address-book", "/module/tessta/clients", []),
						_this.getItemFaIcon("Sites", "fas fa-building", "/module/tessta/sites", []),
						_this.getItemFaIcon("Projects", "fas fa-tasks", "/module/tessta/projects", []),
						_this.getItemFaIcon("QA", "fas fa-check-double", "/module/tessta/qa", []),
						_this.getItemFaIcon("Config", "fas fa-cogs", "/module/tessta/configuration", []),
					],
					() => { _this.changeModuleHandler('tessta'); }
				);
			}
			tesstaModule();





			//----- Module: Tessta Clients -----
			function tesstaClientsModule()
			{
				if (!_this.$root.hasAccess('Tessta.Administration') && !_this.$root.hasAccess('TesstaClients.ClientPortal'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('tesstaClients', "Tessta Client Portal", "fas fa-people-arrows", "", 
					[
						_this.getItemFaIcon("Home", "fas fa-home", "/module/tessta-clients", []),
						_this.getItemFaIcon("Defects", "fas fa-bug", "/module/tessta-clients/defects", []),
						_this.getItemFaIcon("Sites", "fas fa-building", "/module/tessta-clients/sites", []),
						// _this.getItemFaIcon("Assessments", "fas fa-tasks", "/module/tessta-clients/assessments", []),	//TODO: Hidden while not implemented
					],
					() => { _this.changeModuleHandler('tesstaClients'); }
				);
			}
			tesstaClientsModule();

			



			//----- Module: Rostering -----
			function rosteringModule()
			{
				if (!_this.$root.hasAccess('Rostering.RosteringAdministrator'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('rostering', 'Rostering', 'far fa-file-alt', '', 
					[
						_this.getItemFaIcon("Jobs Admin", "fas fa-tasks", "/module/rostering/admin", []),
						_this.getItemFaIcon("Payroll Report", "fas fa-table", "/module/rostering/admin/reports/payroll", []),
						_this.getItemFaIcon("Kiosk", "fas fa-sign-in-alt", "/module/rostering/kiosk", []),
					],
					() => { _this.changeModuleHandler('rostering'); }
				);
			}
			rosteringModule();

			




			//----- Road management (PoC) [OBSOLETE] -----
			function roadManagementProofOfConcept()
			{
				//TODO: PoC is complete. Keep for a period until we know we don't need it again.
				const isDisabled = true;
				if (isDisabled) return;


				if (!_this.$root.hasAccess('Pavements.All'))
				{
					return;
				}

				_this.addItemFaIcon("Pavements (PoC)", "fas fa-road", "/module/pavements-poc", [
					{
						id: "pavements-import",
						text: 'Import',
						icon: '',
						faIcon: 'fas fa-file-import',
						url: '/module/pavements-poc/import',
						children: [],
					},
					{
						id: "pavements-defects",
						text: 'Defects',
						icon: '',
						faIcon: 'fa-solid fa-house-crack',
						url: '/module/pavements-poc/defects',
						children: [],
					},
				]);
			}
			roadManagementProofOfConcept();
			
			



			//----- Lotus Admin / System Admin -----
			function lotusSystemAdmin()
			{
				if( !(_this.$root.hasAccess('LotusAdministration.All') || _this.$root.hasAccess('LotusDevelopment.All')) )
				{
					return;
				}


				let homeItem = null;
				if (_this.$root.isSystemAdminUser())
				{
					//homeItem = { id: "sa0" , text: 'Home', icon: '', faIcon: 'far fa-file-alt', url: '/home/LotusOnly', children: [] };
					homeItem = _this.getItemFaIcon("Home", "fas fa-home", "/home/LotusOnly", []);
				}

				_this.addModuleRootItemFaIcon('lotus', "Lotus", "fas fa-toolbox", "", 
					[
						homeItem,
						//{ id: "sa1", text: "Accounts", url: "/system-admin/accounts"},	//TODO: Obsolete. See next line.
						_this.getItemFaIcon("Accounts", "fas fa-user", "/system-admin/accounts", []),

						// { id: "sa2", text: "System Administrators", url: "/system-admin/system-administrators"},	//TODO: Obsolete
						// { id: "sa3", text: "Users", url: "/system-admin/users"},	//TODO: Obsolete

						//{ id: "sa4", text: "Review Timesheets", url: "/system-admin/review-timesheets"},	//TODO: Obsolete. See next line.
						_this.getItemFaIcon("Review Timesheets", "far fa-clock", "/system-admin/review-timesheets", []),

						// { id: "sa5", text: "Draft Timesheets", url: "/system-admin/timesheets/drafts"},	//TODO: Obsolete. Replaced by "Review Timesheets"
						// { id: "sa6", text: "Submitted Timesheets", url: "/system-admin/timesheets/submitted"},	//TODO: Obsolete. Replaced by "Review Timesheets"
						
						//{ id: "sa7", text: "Reports", url: "/system-admin/reports"},	//TODO: Obsolete. See next line.
						_this.getItemFaIcon("Reports", "far fa-file-alt", "/system-admin/reports", []),

						//{ id: "sa8", text: "Feature Access Definitions", url: "/system-admin/access-definitions"},	//TODO: Obsolete. See next line.
						_this.getItemFaIcon("Feature Access Definitions", "fas fa-key", "/system-admin/access-definitions", []),

						//{ id: "sa9", text: "Developer Guide", url: "/developers/dev-guide"},	//TODO: Obsolete. See next line.
						_this.getItemFaIcon("Developer Guide", "fas fa-border-style", "/developers/dev-guide", []),
					],
					() => { _this.changeModuleHandler('lotus'); }
				);
			}
			lotusSystemAdmin();




			//TODO: [@jason, 9 Aug 2023] Setting this up quickly as an easy way for Narayan and team to access this page 
			//		during development of the Tessta Client Portal.
			function developerGuideInDevAndTest()
			{
				if (!_this.isTest() && !_this.isDevelopment())
				{
					// Only run this is dev and test environments.
					return;
				}

				_this.addModuleRootItemFaIcon('developers', "Developers", "fas fa-toolbox", "", 
					[
						_this.getItemFaIcon("Developer Guide", "fas fa-border-style", "/developers/dev-guide", []),
					],
					() => { _this.changeModuleHandler('lotus'); }
				);
			}
			developerGuideInDevAndTest();
			
			



			//TODO: Obsolete. Access via Lotus Admin
			//-- Developers
			// if( _this.$root.hasAccess('LotusDevelopment.All'))
			// {
			// 	//_this.addItemCustomIcon("System Admin", "icon-dashboard", "/system-admin", systemAdminHomeItems);	//TODO: Obsolete. See "Home".

			// 	//-- Developers menu
			// 	_this.addItemCustomIcon("Developers", "icon-reports", "/developers/dev-guide", 
			// 	[
			// 		{ id: "dev1", text: "Typography", url: "/developers/dev-guide/typography"},
			// 		{ id: "dev2", text: "Forms", url: "/developers/dev-guide/forms"},
			// 		{ id: "dev3", text: "Graphics & Iconography", url: "/developers/dev-guide/graphics"},
			// 		{ id: "dev4", text: "Tables", url: "/developers/dev-guide/Tables"},
			// 		{ id: "dev5", text: "Other", url: "/developers/dev-guide/other"},
			// 		{ id: "dev6", text: "Element UI", url: "/developers/dev-guide/element-ui"},
			// 		{ id: "dev7", text: "Test: Calendar", url: "/developers/dev-guide/test-calendar"},
			// 		{ id: "dev8", text: "Test: Keyword Extraction", url: "/developers/dev-guide/test-keyword-extraction"},
			// 		{ id: "dev9", text: "Mobile App Prototype", url: "/developers/prototypes/mobile-app"},
			// 	]
			// 	);
			// }

			



			//----- Background Jobs -----
			//_this.addItemFaIcon("", "fas fa-bell", "/background-tasks", []);

			



			//-- Temporary Help (should be with the profile icon)
			// _this.addItemCustomIcon("Help", "", "/kb/route/" + _this.$route.name + "?path=" + _this.$route.matched[0].path, []);





			_this.helpUrl = "/kb/with-path/?path=" + _this.$route.matched[0].path;
			// _this.addItemFaIcon("Help", "fas fa-question", helpUrl, []);
		},










		loadCurrentUserInitials()
		{
			// console.log("globalStore.state.currentUser=", this.globalStore.state.currentUser);
			var first = "";
			if (this.globalStore.state.currentUser.firstName.length > 0)
			{
				first = this.globalStore.state.currentUser.firstName.substring(0, 1);
			}
			
			var last = "";
			if (this.globalStore.state.currentUser.lastName.length > 0)
			{
				last = this.globalStore.state.currentUser.lastName.substring(0, 1);
			}

			this.currentUserInitials = first + "" + last;
		},







		


		/**
		 * Add an item with a standard (internal custom image) icon.
		 * 
		 * @param {string} text 
		 * @param {string} icon 
		 * @param {string} url 
		 * @param {array} children 
		 * @param {function} func A function to run rather than navigating to the URL.
		 */
		addItemCustomIcon(text, icon, url, children, func)
		{
			const item = this.getItemCustomIcon(text, icon, url, children, func);

			this.items.push(item);
		},


		addModuleRootItemCustomIcon(module, text, icon, url, children, func)
		{
			const item = this.getItemCustomIcon(text, icon, url, children, func);

			item.module = module;

			this.items.push(item);
		},


		getItemCustomIcon(text, icon, url, children, func)
		{
			this.itemCount++;

			const item = {
				id: this.itemCount,
				text: text,
				icon: icon,
				faIcon: "",
				url: url,
				children: children,
				func: func
			};

			return item;
		},










		/**
		 * Add an item with a Font Awesome icon.
		 * param func: A function to run rather than navigating to the URL.
		 */
		addItemFaIcon(text, faIcon, url, children, func)
		{
			const item = this.getItemFaIcon(text, faIcon, url, children, func);

			this.items.push(item);

			return item;
		},


		addModuleRootItemFaIcon(module, text, faIcon, url, children, func)
		{
			const item = this.getItemFaIcon(text, faIcon, url, children, func);

			item.module = module;

			this.items.push(item);

			return item;
		},


		getItemFaIcon(text, faIcon, url, children, func)
		{
			this.itemCount++;

			const item = {
				id: this.itemCount,
				text: text,
				icon: "",
				faIcon: faIcon,
				url: url,
				children: children,
				func: func
			};

			return item;
		},








		

		stripNullChildren()
		{
			// function spaces(count)
			// {
			// 	return "".padStart(count);
			// }

			function checkAndStripArray(array, debugLevel)
			{
				// Nothing to do
				if (!array || array.length == 0) return;

				// Find and stripp nulls item in the array.
				for (let i = 0; i < array.length; i++)
				{
					//console.log(spaces(debugLevel * 2) + "stripping...", array[i]);
					if (array[i] == null)
					{
						array.splice(i, 1);
					}
				}

				// Go down the tree of children. Do it in a new loop after any nulls are stripped from the array.
				for (let i = 0; i < array.length; i++)
				{
					const children = array[i].children;
				
					if (children && children.length > 0)
					{
						//console.log(spaces(debugLevel * 2) + " - call childre...", children);
						checkAndStripArray(children, debugLevel + 1);
					}
				}
			}


			checkAndStripArray(this.items, 1);
		},










		// isProduction()
		// {
		// 	return config.IsProduction();
		// },





		isDevelopment()
		{
			return this.$root.isDevelopment();
		},





		isTest()
		{
			return this.$root.isTest();
		},





		canAccessKnowledgebase() 
		{
			//return this.$root.hasAccess('Knowledgebase.All');

			// Not needed at the moment. This menu item is a placeholder.
			return false;
		},





		canAccessBackgroundTasks()
		{
			//TODO: Restricted while developing
			//return this.$root.hasAccess('LotusAdministration.All');

			// Not needed at the moment. This menu item is a placeholder.
			return false;
		},





		toggleMenu()
		{
			this.isMobileMenuExpended = !this.isMobileMenuExpended;
		},





		//TODO: Obsolete
		// initMobileMenu()
		// {
		// 	// Setup the mobile version
		// 	$(document).ready(function(){
		// 		$(".icon-mobile-expand").click(function(){
		// 			var dropdownid = $(this).attr('dropdown-id');
		// 			var dropdownid = "ul[dropdown-id='" + dropdownid + "']";
		// 			if($(this).hasClass('expanded')){
		// 				$(this).removeClass('expanded');
		// 				$(dropdownid).stop(0).slideUp(350);
		// 			}else{
		// 				$(this).addClass('expanded');
		// 				$(dropdownid).stop(0).slideDown(350);
		// 			};
		// 			return false;
		// 			event.preventDefault(); 
		// 		});
		// 		$(".mobile-nav .nav-menu-icon").click(function(){
		// 			if($(this).hasClass('closemenu')){
		// 				$(this).removeClass('closemenu');
		// 				$(".mobile-nav-items").stop(0).slideUp(500);
		// 			}else{
		// 				$(this).addClass('closemenu');
		// 				$(".mobile-nav-items").stop(0).slideDown(500);
		// 			}
		// 		});
		// 		$(window).resize(function(){
		// 			$(".mobile-nav .nav-menu-icon").removeClass('closemenu');
		// 			$(".mobile-nav-items").slideUp(500);
		// 		});
		// 	});
		// }



	}
}
</script>










<style>
	
	.nav-header{
		/* border-bottom:1px solid rgba(0,0,0,0.065); */
	}
	.nav-item{
		width:auto;
		display: inline-block;
		float: left;
		cursor: pointer;
		text-align: center;
	}

	.nav-item .is-label
	{
		cursor: default;
	}


	.nav-item .nav-item-text,
	.nav-item .nav-item-text a
	{
		font-weight: 500;
		font-size: 13px;
		color:#576874;
		transition: all ease 0.5s;
	}
	.nav-item:hover .nav-item-text,
	.nav-item:hover .nav-item-text a
	{
		color:#FDB515;
	}
	.nav-item .nav-item-icon {
		display:inline-block;
	}
	.nav-item-padding{
		/* padding:33px 23px 23px 23px; */
		padding:13px 23px 13px 23px;
	}
	.nav-item:hover ul{
		display: block;
	}
	.nav-item ul{
		display: none;
		position: absolute;
		list-style: none;
		padding: 0px;
		background: white;
		border-top: 1px solid rgba(0,0,0,0.065);
		/* box-shadow: rgba(0,0,0,0.09) 0px 3px 30px; */
		box-shadow: rgba(0,0,0,0.59) 0px 3px 30px;
		z-index: 999;
		/* border-radius: 0px 0px 12px 12px; */
		border-radius: 0px 0px 4px 4px;
	}
	.nav-item ul li{
		border-bottom:1px solid rgba(0,0,0,0.065);
		display: block;
	}
	.nav-item ul li:last-of-type{
		border-bottom:none;
	}


	.nav-item ul li .label-child
	{
		/* Emulate: .nav-item ul li a */
		font-size: 14px;
		padding:13px 22px 13px 20px;
		display: block;
		letter-spacing: -0.5px;
		color:#576874;
	}

	.nav-item ul li a
	{
		font-size: 14px;
		padding:13px 22px 13px 20px;
		display: block;
		letter-spacing: -0.5px;
		color:#576874;
	}
	.nav-item ul li a:hover
	{
		color:#FDB515;
	}
	
	/* Personal Account */
	.nav-item-account
	{
		font-weight: bold;
		color: #FDB515;
		font-size: 15px;
		background: #FFFDF5;
		/* border:1px solid #FEF7EA;
		border-radius: 30px; */
		border:1px solid #fdf2dc;
		border-radius: 6px;
		padding: 10px;

		margin: 31px;
		
		margin-top: 13px;
		margin-bottom: 13px;

		margin-left: 13px;
		margin-right: 13px;
	}

	.nav-item-help
	{
		font-weight: bold;
		/* color: #FDB515; */
		color:#576874;
		font-size: 15px;
		/* background: #FFFDF5; */
		/* border:1px solid #FEF7EA; */
		/* border-radius: 30px; */
		padding: 10px;

		/* margin-top: 31px;
		margin-bottom: 31px; */

		margin-top: 13px;
		margin-bottom: 13px;
	}

	.nav-item-right{
		float: right;
	}
	
	/* Dashboard */
	.icon-dashboard{
		background: url("https://assets.lotusai.co/portal/images/icon-dashboard.svg");
		background-repeat: no-repeat;
		height:25px;
		width:25px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-dashboard{
		background: url("https://assets.lotusai.co/portal/images/icon-dashboard-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-timesheets{
		background: url("https://assets.lotusai.co/portal/images/icon-timesheets.svg");
		background-repeat: no-repeat;
		height:22px;
		width:22px;
		margin-top:1px;
		margin-bottom:2px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-timesheets{
		background: url("https://assets.lotusai.co/portal/images/icon-timesheets-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-projects{
		background: url("https://assets.lotusai.co/portal/images/icon-projects.svg");
		background-repeat: no-repeat;
		height:23px;
		width:23px;
		margin-top:2px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-projects{
		background: url("https://assets.lotusai.co/portal/images/icon-projects-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-reports{
		background: url("https://assets.lotusai.co/portal/images/icon-reports.svg");
		background-repeat: no-repeat;
		height:23px;
		width:30px;
		margin-top:2px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-reports{
		background: url("https://assets.lotusai.co/portal/images/icon-reports-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-approve{
		background: url("https://assets.lotusai.co/portal/images/icon-approve.svg");
		background-repeat: no-repeat;
		height:20px;
		width:20px;
		margin-top:2px;
		margin-bottom:3px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-approve{
		background: url("https://assets.lotusai.co/portal/images/icon-approve-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-teams{
		background: url("https://assets.lotusai.co/portal/images/icon-teams.svg");
		background-repeat: no-repeat;
		height:20px;
		width:30px;
		margin-top:3px;
		margin-bottom:2px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-teams{
		background: url("https://assets.lotusai.co/portal/images/icon-teams-hover.svg");
		background-repeat: no-repeat;
	}
	.mobile-nav{
		display: none;
	}
	.mobile-nav .nav-menu{
		display: block;
		/* padding: 33px 0px 33px 26px; */
		padding: 10px 0px 10px 10px;
		float: left;
		width: auto;
	}
	.mobile-nav .nav-menu-icon{
		width:90px;
		height: 40px;
		display: block;
		text-align: right;
		display: block;
		float: left;
		background: url("https://assets.lotusai.co/portal/images/icon-mobilemenu.svg");
		background-repeat: no-repeat;
		cursor: pointer;
		color: #576874;
		font-size: 14px;
		padding: 8px 0px;
	}
	.mobile-nav .nav-menu-icon.closemenu{
		background: url("https://assets.lotusai.co/portal/images/icon-mobilemenu-close.svg");
		background-repeat: no-repeat;
	}
	.mobile-nav-items{
		width: 100%;
		/* display: none; */
		display: block;
	}
	.mobile-nav-items ul, .mobile-nav-items ul li{
		width: 100%;
		display: block;
		list-style: none;
		padding:0;
		text-align: left;
	}
	.mobile-nav-items ul li .container{
		border-bottom:1px solid rgba(0,0,0,0.065);
		width:100%;
		display: block;
		/* font-size: 14px; */
		/* color: #576874; */
		padding:15px 15px 15px 43px;
	}
	.mobile-nav-items ul li a{
		/* border-bottom:1px solid rgba(0,0,0,0.065); */
		/* width:100%; */
		width: 80%;
		/* display: block; */
		display: inline-block;
		font-size: 14px;
		color: #576874;
		/* padding:15px 15px 15px 43px; */
	}
	.mobile-nav-items ul li a:hover{
		color:#FDB515;
		transition: all ease 0.5s;
	}
	.mobile-nav-items .icon{
		float: left;
		margin-right: 20px;
		width:30px;
	}
	.mobile-nav-items .icon-mobile-expand{
		float: right;
		display: block;
		background: url("https://assets.lotusai.co/portal/images/icon-mobile-expand.svg");
		width: 20px;
		height: 12px;
		background-repeat: no-repeat;
		cursor: pointer;
		margin: 6px 7px 0px 0px;
		transition:all 0.5s ease;
	}
	.mobile-nav-items .icon-mobile-expand.expanded{
		transform: rotate(180deg);
		margin:5px 7px 0px 0px;
	}
	.mobile-nav-items ul li ul{
		float: left;
		position: relative;
		box-shadow: none;
		border:none;
		background: #F7F9FA;
		display: none;
	}
	.mobile-nav-items ul li:hover ul{
		display: none;
	}
	
	.mobile-nav-items ul li ul li{
		border: none;	
	}
	
	.mobile-nav-items ul li ul li a{
		padding:15px 15px 15px 93px;
	}
	
	/* @media (max-width: 991px)  */
	@media (max-width: 996px) 
	{
		.nav-item-padding{
			padding:33px 15px 23px 15px;
		}
	}
	
	/* @media (max-width: 767px)  */
	@media (max-width: 996px) 
	{
		.desktop-nav{
			display: none;
		}
		.mobile-nav{
			display:block;
		}
	}
</style>