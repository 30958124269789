<template>
	<!-- <div :class="[globalStore.state.pageLayoutClass, sectionLayoutClass]"> -->
	<div class="container-fluid">

		<div class="inner-container">

			<!-- <div class="col-12 bg-white shadow-sm pb-3"> -->
			<div class="col-12">
				
				<!-- // Adaptive all screens screens -->
				<div class="row">
					<div class="col-12 col-md-1 text-center icon-container">
						<a href="/">
							<img src="https://assets.lotusai.co/portal/images/logo-gold-transparent.png" alt="Lotus AI logo" class="header-logo mb-4" style="max-height: 50px; margin-top: 25px" title="Lotus AI">
						</a>
					</div>
					<div class="col-12 col-md-11" >
						<el-card id="primary-top-nav-card" class="box-card" style="margin-bottom: 20px;">
							<primary-top-nav></primary-top-nav>
						</el-card>
					</div>
				</div>


				<!-- // Page Title -->
				<div id="page-title-container" class="page-title-container" v-if="getPageTitle" style="width: 100%;">
					<div v-if="layoutType == 'default'">
						<c-layout-container class="page-title-card" height="auto" v-if="1==2">
							<page-title><span v-html="getPageTitle"></span></page-title>
						</c-layout-container>

						<c-layout-container type="none" class="page-title-card mb-4" height="auto">
							<page-title :version="2"><span v-html="getPageTitle"></span></page-title>
						</c-layout-container>
					</div>

					<div v-if="layoutType == 'account-admin' || layoutType == 'manage'">
						<div class="row">
							<div class="col-12">
								<!-- <c-layout-container type="card" class="page-title-card" height="auto">
									<page-title>
										<span v-html="getPageTitle"></span>
										<span style="font-size: 100%" v-if="accessAccountId != globalStore.state.currentUser.activeAccountId"> &gt; <i>{{account.name}}</i></span>
									</page-title>
								</c-layout-container> -->

								<div class="col-12 pb-3 mt-1 d-block d-lg-none">
									<!-- Mobile size navigation -->
									<c-layout-container type="card" class="page-title-card" height="auto">
										<el-collapse>
											<el-collapse-item name="menu">
												<template #title>
													<i class="fas fa-bars"></i>&nbsp;&nbsp;Section Menu
												</template>
												<sub-navigation :account-id="accessAccountId" :layout-type="layoutType"></sub-navigation>
											</el-collapse-item>
										</el-collapse>
									</c-layout-container>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="v-margin-normal" v-if="pageTitle"></div> -->




				<!-- // Page Content -->
				<div class="page-content-container">
					<div class="default-layout" v-if="layoutType == 'default'">
						<div class="row">
							<!-- <div class="col-12 pl-4 pr-4 pb-3"> -->
							<div class="col-12 pb-3">
								<c-layout-container id="primary-layout-page-content-card" :type="getContentContinerType()">
									<slot></slot>
								</c-layout-container>
							</div>
						</div>
					</div>


					<div class="manage-layout" v-if="layoutType=='manage' || layoutType=='account-admin'">
						<div class="row">
							<div class="col-12 col-md-12 col-lg-2 pb-3 d-none d-lg-block" id="manage-navigation-container">
								<!-- Desktop size navigation -->
								<sub-navigation :account-id="accessAccountId" :layout-type="layoutType"></sub-navigation>
							</div>
							<div class="col-12 col-md-12 col-lg-10">
								<c-layout-container type="card" class="page-title-card mb-2" height="auto" v-if="1 == 2">
									<page-title>
										<span v-html="getPageTitle"></span>
										<span style="font-size: 100%" v-if="accessAccountId != globalStore.state.currentUser.activeAccountId"> <span v-html="getPathSeparator()"></span> <i>{{account.name}}</i></span>
									</page-title>
								</c-layout-container>

								<c-layout-container type="none" class="page-title-card mb-4" height="auto">
									<page-title :version="2">
										<span v-html="getPageTitle"></span>
										<span style="font-size: 100%" v-if="accessAccountId != globalStore.state.currentUser.activeAccountId"> <span v-html="getPathSeparator()"></span> <i>{{account.name}}</i></span>
									</page-title>
								</c-layout-container>

								<div class="page-content-container">
									<!-- //TODO: Trying to get a value passed back up from a child element -->
									<!-- <el-card class="box-card" style="width: 100%; margin-bottom: 10px">
										pageSubTitle={{pageSubTitle}} | {{subPageTitle}}
									</el-card> -->
									<!-- NOTE: I don't think pageSubTitle and pageTitle are doing anything on the following <slot> -->

									<!-- //TODO: Switch teh type="card" to type="none" and then wrap required content in each page in <c-layout-container type="card"> as required.-->
									<c-layout-container id="primary-layout-page-content-manage-admin" :type="getContentContinerType()">
										<slot :pageSubTitle="pageSubTitle" @pageTitle="onPageTitle"></slot>	
									</c-layout-container>
								</div>
							</div>
						</div>
					</div>
					
				</div>

				
				<div class="mt-2 pb-2" id="footer-layout-container">
					<c-layout-container class="page-title-card" height="auto">
						<layout-footer></layout-footer>
					</c-layout-container>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import globalStore from '/globalStore';
import layoutMixin from "./layout-mixin";
import layoutFooter from "./footer.vue";
import subNavigation from "/layouts/navigation/sub-navigation.vue";
import accountAdminMixin from "/account-admin/account-admin-mixin";



export default {
	name: "PrimaryLayout",
	
	emits: ["child-event"],

	mixins: [layoutMixin, accountAdminMixin],

	components: {
		'layout-footer': layoutFooter,
		'sub-navigation': subNavigation
	},



	props: [
		'subPageTitle'
	],


	//TODO: Obsolete. REmove.
	// computed: {
	// 	contentContinerType()
	// 	{
	// 		return getContentContainerType();
	// 	}
	// },

	data() {
		return {
			globalStore: globalStore,

			pageTitle: "",
			pageSubTitle: "",

			section: "",
			sectionLayoutClass: "",

			layoutType: "",	// The "layout-type" specified for the page in the route meta. "account-admin" or "" (default)

			layoutContainer: "", // specified in route meta data. Default is "card"


			//-- For "account-admin" layouts
			accessAccountId: ""
		};
	},



	mounted() 
	{
		this.init();
	},


	created() 
	{
		this.init();
	},

	// This is to derive the pageTitle from route, which will change.
	// This works, but using computed instead.
    // watch: {
	// 	$route(to, from) {
	// 		this.pageTitle = to.meta.pageTitle;
	// 	}
	// },

	computed: {
		getPageTitle() {
			var _this = this;

			var pageTitleRaw = this.$route.meta.pageTitle;

			var pathParts = pageTitleRaw.split(' > ');

			var finalTitle = "";
			pathParts.forEach((part) => {
				if (finalTitle != "")
				{
					//finalTitle = finalTitle + ' <i class="fas fa-angle-right"></i> ';	
					finalTitle = finalTitle + ' ' + _this.getPathSeparator() + ' ';	
				}
				finalTitle = finalTitle + part;
			});

			//return pageTitleRaw;
			return finalTitle;
		}
	},


	methods: {


		init() 
		{
			//console.log("PrimaryLayout.init(): 1");

			this.pageTitle = this.$route.meta.pageTitle;

			//console.log("this.$route.meta.layout=", this.$route.meta.layout);
			//this.layoutType = this.$route.meta.layout;

			this.layoutType = this.$route.meta.layoutType;
			if (!this.layoutType) this.layoutType = "default";

			this.layoutContainer = this.$route.meta.layoutContainer;
			//console.log("layoutContainer=", this.layoutContainer);

			if (!this.layoutContainer) this.layoutContainer = "card";
			//console.log("layoutContainer 2=", this.layoutContainer);
			
			// Check the user is [still] logged in.
			this.$root.checkCurrentAuthTokenStillValid();


			// this.$on("child-event", this.handleChildEvent);	//TODO: Remove

			this.$parent.$emit('child-event', 'primary layout emit');


			this.authoriseAccessIfSystemAdmin();




			this.initForAccountAdmin();
		},



		getPathSeparator()
		{
			return '<i class="fas fa-long-arrow-alt-right"></i>';
		},


		initForAccountAdmin()
		{
			this.accessAccountId = this.getCurrentAccountAdminAccountId();

			if (this.isAccountAdmin())
			{
				this.loadAccount();		// From: accont-admin-mixin.js
			}
		},





		isAccountAdmin()
		{
			//TODO: Do this properly, with a feature access check. But for the moment it's fine.
			//		(Note: the `layoutType == 'account-admin'` probably doesn't need to change in the HTML part of this file when we change this method)
			return this.layoutType == 'account-admin';
		},


		/**
		 * Authorise user access to this page(s) if trying to access /system-admin.
		 */
		authoriseAccessIfSystemAdmin()
		{
			var path = this.$root.getCurrentRoutePath();
			//console.log("authoriseAccessIfSystemAdmin(): path=", path);

			var isSystemAdminPage = path.startsWith("/system-admin/");
			//console.log("authoriseAccessIfSystemAdmin(): isSystemAdminPage=", isSystemAdminPage);

			if (isSystemAdminPage == false)
			{
				// This page is not for system admin, so skip this check and keep loading.
				return;
			}

			if (this.$root.isSystemAdminUser() == false)
			{
				// User cannot access system admin pages, so rediect to the standard home page.
				this.$root.redirectToHomePage();
				
				return;
			}
			
			// console.log("SystemAdmin: Has access");
		},



		onPageTitle(data)
		{
			//console.log("PrimaryLayout.onPageTitle(): data=", data);
		},


		//TODO: Remove
		// handleChildEvent(data)
		// {
		// 	console.log("PrimaryLayout.handleChildEvent(): data=", data);
		// },

		
		getContentContinerType()
		{
			//console.log("contentContinerType()");
			if (this.layoutContainer == 'card') return "card";
			if (this.layoutContainer == 'none') return "none";

			return "card";
		}


	}
}
</script>






<style lang="scss">
	@import "./shared.scss";
</style>

<style lang="scss" scoped>
	.inner-container
	{
		//TDOO: With the variable #app background this is causing issues
		//margin-top: 5px;	
		padding-top: 5px;	//.. but this  does the trick.
	}


	
	#primary-top-nav-card
	{
		overflow: inherit;
	}


</style>

<style lang="scss">


	//TODO: Review these now we have "c-layout-container"
	#page-title-container .page-title-card 
	{
		.el-card__body
		{
			padding: 15px;
			padding-left: 20px;
		}
	}


	#primary-top-nav-card
	{
		.el-card__body
		{
			padding: 10px;
			// padding-left: 20px;
		}
	}



	// #footer-layout-container
	// {
	// 	//-- Stick and always visible at the bottom.
	// 	// position: sticky; 
	// 	// bottom: 0;
	// 	// z-index: 10000;	// Make sure it's on top of everything (problem: Element IO drop downs slide behind it.)
	//
	// 	//NOTE: Decided not to have it sticky. Doesn't quite feel right.
	// }

	#footer-layout-container .el-card__body
	{
		width: 100%;
		
		padding-top: 10px;
		padding-bottom: 10px;
	}



	@media screen and (max-width: 767px) {
		.header-logo
		{
			height: 40px;
			margin-top: 5px !important;
			margin-bottom: 5px !important;
		}

		.icon-container
		{
			padding: 0;
		}
	}
</style>