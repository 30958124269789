<template>
	<button
		:id="id"
		:class="['radius-4px', 'text-dark', 'py-2', 'btn', 'btn-outline-primary', 'btn-sm', cssClass]" 
		@click="onClick" 
		:disabled="disabled ? true : false"
		:title="title"
	>
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
	</button>
</template>

<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {
	name: 'ActionButton',

	emits: ["click"],

	mixins: [allFormElementPropsMixin, buttonPropsMixin],

	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		onClick() {
			this.$emit('click');
		},
	},
};
</script>

<style scoped lang="scss">

	.radius-4px {
		border-radius: 4px;
	}

</style>

<style scoped lang="scss">
	.icon {
		margin-right: 10px		
	}
</style>