<template>
	<li :class="['nav-item', getIsModuleReturnClass]">
		<span class="container">
			<!-- Icon + Text: Original -->
			<a :href="getItem.url" style="display: inline-block" v-if="1==2">
				<!-- //NOTE: Icons just take up space in the mobile view. Hide for now -->
				<!-- <span :class="['icon', getItem.icon]"></span> -->
				{{getItem.text}}
			</a>

			<!-- Icon + Text: Has a URL -->
			<a v-if="getItem.url != ''" :href="getItem.url">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
				<span class="item-text">{{getItem.text}}</span>
			</a>

			<!-- Icon + Text: No URL, but has a function, just a label -->
			<span v-if="getItem.url == '' && item.func" @click="item.func">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
				<span class="item-text">{{getItem.text}}</span>
			</span>

			<!-- Icon + Text: No URL or function, just a label -->
			<span v-if="getItem.url == '' && !item.func">
				<span v-if="getItem.icon != ''" :class="['nav-item-icon', getItem.icon]"></span>
				<span class="fa-icon" v-if="getItem.faIcon">
					<i v-if="getItem.faIcon != ''" :class="['nav-item-icon', getItem.faIcon]"></i>
				</span>
				<span class="item-text">{{getItem.text}}</span>
			</span>



			<!-- "Show sub-item" expander -->
			<span class="icon-mobile-expand" style="display: inline-block"
				v-if="getItem.children && getItem.children.length > 0" 
				:dropdown-id="getItem.id" 
				@click.stop="toggleMenu()">
			</span>
		</span>


		<ul v-bind:dropdown-id="getItem.id" v-if="getItem.children && getItem.children.length > 0 && isSubMenuExpended" style="display: block">
			<li v-for="child in getItem.children" :key="getItem.id + child.id">
				<a :href="child.url">
					<span v-if="child.html" v-html="child.html"></span>
					{{child.text}}
				</a>
			</li>
		</ul>
	</li>
</template>


<script>
export default {
	props: [
		'item'
	],

	data()
	{
		return {
			isSubMenuExpended: false
		};
	},



	computed: {
		/**
		 * Return the passed in item. Return via a computed so "undefined" during render is handled gracefully.
		 */
		getItem()
		{
			if (this.item == undefined) return {
				children: []
			};

			return this.item;
		},

		getModule()
		{
			if (this.item == undefined) return '';

			if (this.item.module) return this.item.module;

			return '';
		},

		getIsModuleReturnClass()
		{
			if (this.getModule == "--module-return--") return 'module-return-item';

			return '';
		}
	},



	mounted()
	{
		this.init()
	},

	


	methods: {
		init()
		{
		},



		toggleMenu()
		{
			this.isSubMenuExpended = !this.isSubMenuExpended;
		},
	}
}
</script>





<style lang="scss" scoped>
	.item-text
	{
		display: inline-block;
		margin-left: 1rem;	
	}





	//-- Overrides for the special "module return" nav item.
	.module-return-item
	{
		margin-right: 1.5rem;
	}

	.module-return-item .item-text
	{
		// font-weight: bold;
		// color: navy;
		font-style: italic;
		// text-decoration: underline;

		color:#FDB515;
	}
	.module-return-item .nav-item-text:hover
	{
		color:#576874;
	}

	.module-return-item .fa-icon
	{
		// height: 22px;
		// width: 22px;
		// display: inline-block;


		// .svg-inline--fa
		// {
		// 	height: 1.6em;		//20 Oct 2020 [JS]: Just a rushed eyeball approximation.	//TODO: Review and fix
		// }

		color:#FDB515;

	}
	.module-return-item .fa-icon:hover
	{
		color:#576874;
	}
</style>