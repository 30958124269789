<template>
	<div :class="{ 'plain-table': (enabled == false) } ">
		<slot></slot>
	</div>
</template>





<script>
/*
Ref: https://github.com/fiduswriter/Simple-DataTables
*/

export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		
		/**
		 * Increment the number when the table data has finished updating, 
		 * so the watcher will call loadDataTable()
		 */
		updater: {
			type: Number
		},



		/**
		 * Disable and display a normal table if necessary.
		 */
		enabled: {
			type: Boolean,
			default: false
		}
	},






	watch: {
		'updater': function(to, from)
		{
			//console.log("simple-datatable.watch(updater): from=", from, "to=", to, " | enabled=", this.enabled); 

			this.loadDataTable();
		},
	},





	data()
	{
		return {

		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {
		init()
		{

		},


		loadDataTable()
		{
			//console.log("simple-datatable.loadDataTable() enabled=", this.enabled); 
			if (this.enabled.toString() == false.toString()) 	//toString() because <simple-datatable enabled="false">...</simple-datatable> is not bound attribute.
			{
				//console.log("stopping");
				return;
			}

			// setTimeout(() => {
			// 	//NOTE: SetTimeout to allow Vue time to load and render the data.
			// 	// const dataTable = new DataTable("#users-table", {
			// 	// 	searchable: true,
			// 	// 	fixedHeight: true,
			// 	// })
			// 	let myTable = document.querySelector("#" + this.id + "");
			// 	let dataTable = new DataTable(myTable);
			// }, 200);
		}
	}
}
</script>



<!-- 
// Original
<style scoped lang="scss">
	.plain-table
	{
		table,
		tbody
		{
			width: 100%;
			th
			{
				padding-right: 8px;
			}

			tr
			{
				td
				{
					padding-bottom: 8px;
					padding-right: 8px;
					font-size: 10pt;
				}
			}
		}
	}
</style>
 -->


<!--  
// Version 1

<style scoped lang="scss">

	.plain-table
	{
		:deep(table),
		:deep(tbody)
		{
			width: 100%;
			:deep(th)
			{
				padding-right: 8px;
			}

			:deep(tr)
			{
				:deep(td)
				{
					padding-bottom: 8px;
					padding-right: 8px;
					font-size: 10pt;
				}
			}
		}
	}
</style> -->


<style scoped lang="scss">
	.plain-table:deep
	{
		table,
		tbody
		{
			width: 100%;
			th
			{
				padding-right: 8px;
			}

			tr
			{
				td
				{
					padding-bottom: 8px;
					padding-right: 8px;
					font-size: 10pt;
				}
			}
		}
	}
</style>